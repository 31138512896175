@import "../../../../../../../assets/sass/variables";

.customCol-1-4-6 {
  max-width: fit-content !important;
  margin: 10px 0;
}
.customRow {
  justify-content: center;
}
.box {
  opacity: 1;
}
.custom-header {
  margin-left: 11% !important;
}

.trackItemBandCard {
  width: 60px;
  border-radius: 4px 4px 0 0 !important;
  font-weight: bolder !important;
  text-shadow: 0px 0px, 0px 0;
  margin-bottom: unset !important;

  box-shadow: 0px 0px 6px 3px #f5f5f5;
  .ant-card-body {
    padding: 0.6rem 1.5rem 0.4rem !important;
  }
  .ant-card-body {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
}

////////////////////////chart variables ///////////////////////////////

.chart_info_action_button {
  color: #17a99a;
  background-color: #17a99a1a;
  svg {
    fill: #17a99a;
  }
  // svg {
  //   fill: #6e6b7b;
  // }
  // &:hover{
  //   color:#17a99a ;
  //   background-color: #17a99a1a;
  //   svg {
  //     fill: #6e6b7b;
  //   }
  // }
}
.chart_variable_popover_container {
  width: 35%;
  z-index: 1060;
  @media only screen and (max-width: 1290px) {
    width: 45%;
  }
  @media only screen and (max-width: 1130px) {
    width: 50%;
  }

  @media only screen and (max-width: 1030px) {
    width: 60%;
  }

  @media only screen and (max-width: 850px) {
    width: 65%;
  }

  .ant-popover-inner-content {
    padding: 12px;
  }

  .customInformation {
    margin: unset;
    padding: unset;
    .col-6,
    .col-12 {
      margin-bottom: 1%;
    }
    .chart_variable_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h6 {
      font-size: 10px;
      margin-bottom: 4px !important;
      color: #00000080;
    }
    .color-black {
      font-size: 11px;
      font-weight: bold;
    }
  }
}

.customInformationModal {
  margin: unset;
  padding: unset;
  .col-3,
  .col-12 {
    margin-bottom: 2%;
  }
  h6 {
    font-size: 12px;
    margin-bottom: 4px !important;
    color: #00000080;
  }
  .color-black {
    font-weight: bold;
  }
  .customRating {
    .numeric-colored-ranges {
      margin: 15px 40px;
      align-items: center;
    }
  }
}

.relations_container {
  border-top: 1px solid #dbdbdb;
  background: #ffffff;
  padding: 10px;
  border-radius: 0px 0px 6px 6px;

  .header_text {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .ant-btn.ant-btn-default {
    background: white;
    color: black;
    margin-bottom: 8px;
    svg {
      fill: #6e6b7b;
    }
    &:hover {
      border: 1px solid #17a99a;
      box-sizing: border-box;
      background-color: white;
      color: #17a99a;
      svg {
        fill: #17a99a;
      }
    }
    &:focus,
    &:active {
      border: 1px solid #17a99a;
      box-sizing: border-box;
      background: #17a99a;
      color: white;
      svg {
        fill: #17a99a;
      }
    }
  }
}
////////////////////////chart variables ///////////////////////////////

.chartValues {
  padding-top: 30px;
  // padding: 25px 0 10px;
  // margin-left: 85px;
  // margin-right: 9px;
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;

  .chartDescription {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    z-index: 1000;
  }

  .arrow-pointer {
    width: auto;
    height: 32px;
    background: #01a443;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px 0 28px;

    &:after {
      content: "";
      position: absolute;
      left: -0.5px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 17px solid #ffffff;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
    }
  }
  .label-section {
    display: inline-flex;
    height: 32px;
  }
}

.axis-font {
  font-size: 10px;
  // @media (max-width: 1000px) {
  //   font-size: 2vmin;
  // }
}

.projection-chart {
  display: flex;
  text.recharts-text.recharts-label {
    font-weight: bold;
    text-shadow: 0px 0px, 0px 0;
  }
  .recharts-cartesian-axis-ticks {
    font-weight: bold;
    text {
      fill: black;
    }
  }
  svg.recharts-surface {
    // height: 63vh;
    overflow: visible;
  }
}

.recharts-legend-wrapper {
  display: inline-block;
  // padding: 5px 0;
  svg.recharts-surface {
    display: none !important;
  }
}

.customLegend {
  display: flex;
  justify-content: space-between;
}

.customLegendItems {
  color: white;
  border-radius: 5px;
  padding: 7px;
  display: inline-flex;
  margin-left: 10px;

  &:nth-child(1) {
    margin-left: unset;
  }

  &:nth-child(2) {
    margin-left: 15px;
  }
}

.d-inline {
  display: inline-flex !important;
  align-items: center;
  margin: unset;
  margin-left: 2rem;
}

.custom-chart-tooltip {
  font-weight: bold;
  padding: 10px;
  background: white;
  border: 0.5px solid #ebebeb;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06);
}

.horizontalList {
  margin: 4px, 4px;
  padding: 4px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.selectedRelationItem {
  padding: 0.86923rem;
  border-bottom: 1px solid #0302290d !important;
}

.exportItemsBox {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 25px;
  .exportItems {
    cursor: pointer;
    padding: 10px 35px;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    margin: 10px;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid $primary-bg-color;
      box-sizing: border-box;
      background-color: white;
      color: $primary-bg-color;
    }
    svg {
      color: #00bca4;
    }
  }
  .selectedItem {
    padding: 10px 35px;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    margin: 10px;
    background-color: $primary-bg-color;
    color: white;
  }
}

.relations_container {
  padding-left: 10px !important;
  max-width: 100%;
  width: 1400px;
  margin: 0px auto;
}
.scrollDiv {
  max-height: 50vh !important;
  overflow-y: auto !important;
}

.mainChartScale {
  width: 30px;
  height: 35vh;
  margin-top: 7px;
  margin-left: -11px;
}

.chartArea {
  .historicalChartPopup {
    height: 34rem;
  }
  .historicalChartScale {
    right: -30px !important;
    margin-top: 7.07px;
    height: 87.4% !important;
  }
}
.trackedItemActions {
  li .viewFinancialSheet {
    svg,
    path,
    rect {
      fill: none !important;
      stroke: #6e6b7b;
    }
  }
  li:hover .viewFinancialSheet {
    svg,
    path,
    rect {
      fill: none !important;
      stroke: #1b998f;
    }
  }
  .ant-dropdown-menu-title-content {
    .rating-graph-icon {
      svg {
        fill: transparent !important;
        path {
          fill: transparent !important;
          stroke: #6e6b7b !important;
        }
      }
    }
    &:hover {
      .rating-graph-icon {
        svg {
          // fill: $primary-bg-color !important;
          path {
            stroke: $primary-bg-color !important;
          }
        }
      }
    }
  }
}
