@import "../../../assets/sass/variables";

#resizable-table .react-resizable {
  position: relative;
  background-clip: padding-box;
}

#resizable-table .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.ant-table-wrapper {
  .ant-table-tbody tr {
    height: 50px;
  }
}

.customListing {
  .ant-table-wrapper {
    .ant-table-tbody tr {
      height: 56px;
    }
  }
}

.ant-table {
  background: unset;
  font-size: 13px;
}

.ant-table-wrapper {
  .ant-table-thead tr > th {
    border-bottom: 1px solid #e9e9e9 !important;
    background: unset;
  }
}

.ant-table-tbody {
  > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
    &:hover {
      box-shadow: none !important;
      > td {
        border-color: $default-bg-color !important;
        border-bottom: unset;
        box-shadow: inset 0px 10px 5px -10px #d4d4d4,
          inset 0px -10px 5px -10px #d4d4d4 !important;
      }
    }
  }
}

.customListing .ant-table-wrapper .ant-table-tbody > tr > td {
  border-top: 8px solid $bg-color;
}

.dashboardListing {
  .ant-table-wrapper {
    .ant-table-tbody tr {
      height: 143px;
    }
  }
}

.customListing,
.dashboardListing,
.trackedItemListing {
  // margin-top: 10px;
  .ant-table-wrapper {
    .ant-table-tbody > tr > td {
      background: white;
      // border: unset !important;
    }
  }
  .ant-table-tbody
    > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
    box-shadow: 0 0 4px #e6e6e6;
    &:hover {
      > td {
        border-color: $bg-color !important;
        background: white !important;
      }
    }
  }
}

.trackedItemListing {
  .ant-table table {
    border-spacing: 0 5px;
  }
  .ant-table-wrapper {
    .ant-table-tbody > tr > td {
      border: unset !important;
    }
  }
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: unset;
}

.ant-table-wrapper .ant-table tr th,
.ant-table-wrapper .ant-table tr td {
  padding: 0.6rem;
}

.ant-table-wrapper .ant-table tr td:last-child {
  padding-right: 0.6rem;
}

.ant-table-wrapper .ant-table .ant-table-expanded-row td + td {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  .refresh-time-sub-categories {
  color: #0c6c62;
  font-weight: bolder;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first
  .ant-table-cell-content {
  display: unset;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-row.nestedRow:not(.expandedRow)
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content
  > span:first-child {
  display: inline-block;
  overflow: hidden;
  max-width: calc(100% - 30px);
  text-overflow: ellipsis;
}
.ant-table-row:not(.expandedRow)
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content
  > span:last-child
  .summary_actions_buttons {
  margin: 0px 5px;
}
.ant-table-row:not(.expandedRow)
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content
  > span:last-child {
  display: inline-block;
}
.ant-table-wrapper
  .ant-table-tbody
  tr.nestedRow:hover
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content
  > span:first-child,
.ant-table-wrapper
  .ant-table-tbody
  tr.nestedRow.selectedRow
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content
  > span:first-child {
  overflow: visible;
  max-width: 100%;
  white-space: break-spaces;
}

.ant-table-wrapper
  .ant-table-tbody
  tr.nestedRow:hover
  .ant-table-cell-ellipsis
  > span:first-child,
.ant-table-wrapper
  .ant-table-tbody
  tr.nestedRow.selectedRow
  .ant-table-cell-ellipsis
  > span:first-child {
  overflow: visible;
  max-width: 100%;
  white-space: break-spaces;
  border-top: 0px;
}
.ant-table-wrapper
  .ant-table-tbody
  tr.nestedRow:hover
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content,
.ant-table-wrapper
  .ant-table-tbody
  tr.nestedRow.selectedRow
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content {
  display: inline-flex;
  align-items: center;
  width: calc(100% - 40px);
  justify-content: space-between;
}

.ant-table-row.expandedRow:hover > td.ant-table-cell:first-child,
.ant-table-row.boldRow:hover > td.ant-table-cell:first-child {
  display: inline-flex;
  align-items: center;
  min-height: 50px;
  width: 100%;
}

.ant-table-wrapper
  .ant-table-tbody
  tr.expandedRow:hover
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content,
.ant-table-wrapper
  .ant-table-tbody
  tr.boldRow:hover
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content {
  display: flex;
}

.ant-table-wrapper
  .ant-table-tbody
  tr.expandedRow:hover
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content
  > span:first-child,
.ant-table-wrapper
  .ant-table-tbody
  tr.boldRow:hover
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
  .ant-table-cell-content
  > span:first-child {
  white-space: break-spaces;
  display: block;
}

.ant-table-wrapper .ant-table tr th:first-child,
.ant-table-wrapper .ant-table tr td:first-child {
  padding-left: 0.42857rem;
}

.fixedColumnListing .ant-table-wrapper .ant-table tr td:first-child {
  // box-shadow: 3px 1px 4px rgb(3 2 41 / 8%);
  padding-left: 2px;
}

.ant-table-wrapper .ant-table tr th:first-child,
.ant-table-wrapper .ant-table tr td:first-child {
  padding-left: 1.42857rem;
}

.ant-table-column-has-sorters:hover {
  background: transparent !important;
}

.ant-input,
.ant-dropdown-menu-item {
  font-size: 12px;
}

.ant-table-pagination {
  display: none !important;
}

.ant-pagination-options {
  display: none !important;
}

.ant-pagination {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 7px;
  right: 0px;

  .ant-pagination-prev {
    width: 25px;
    height: 25px;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-pagination-item-link {
      color: unset;
      width: 25px;
      height: 25px;
      border: 1.44px solid #e4e4e4;
      background-color: $bg-color !important;
      &:hover {
        color: unset;
      }
    }
  }

  .ant-pagination-item {
    min-width: 25px !important;
    min-height: 25px !important;
    width: 25px;
    height: 25px;
    a {
      width: 25px;
      height: 25px;
      color: unset;
      background-color: unset !important;
    }
    &-active,
    &:hover {
      background-color: $primary-bg-color !important;
      a {
        color: white !important;
      }
    }
  }

  // .ant-pagination-item-active,
  // .ant-pagination-item:hover {
  //   background-color: $primary-bg-color !important;
  //   color: white !important;
  // }
}

.pagination_info {
  margin: 0px 5px 0 0;
  font-weight: bold;
}

// .ant-table-column-sorter-up.active,
// .ant-table-column-sorter-down.active {
//   color: $primary-bg-color;
// }
.visionCommentsTooltip .ant-tooltip-inner {
  max-width: 400px !important;
  min-width: auto !important;
  width: max-content;
  font-size: 98%;
}
.notification-dropdown .ant-select-selector {
  max-height: 40px;
}

.visionCommentsContainer {
  align-items: start;
}
.visionCommentsContainer > span:first-child {
  width: 80%;
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

textarea[name="visionComments"] {
  background-color: white;
}
div[data-test="global-notifications-listing-table"] .ant-table-tbody td > div {
  overflow: hidden;
  text-overflow: ellipsis;
}
