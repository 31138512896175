@mixin arrow-color-map($map) {
  @each $color-key, $color-value in $map {
    &.#{$color-key}:after {
      border-color: $color-value transparent transparent transparent;
    }
  }
}

@mixin background-color-map($map) {
  @each $color-key, $color-value in $map {
    &.#{$color-key} {
      background: $color-value !important;
    }
  }
}

.grades {
  text-align: center;
  display: inline-flex;
  font-size: 9px;
  font-weight: bold;
  width: 96px;
  justify-content: center;
}

.numeric-colored-ranges {
  $local-colors: (
    "color-green": #92d050,
    "color-red": #ff0000,
    "color-darkGreen": #00b050,
    "color-amber": #ffc000,
    "color-grey": rgba(0, 0, 0, 0.05),
  );

  $local-colors-lighten: (
    "color-green": #92d05080,
    "color-red": #ff000080,
    "color-darkGreen": #00b0509e,
    "color-amber": #ffc00080,
  );

  display: flex;
  flex-direction: column;
  margin-left: 30px;

  .range-values,
  .colored-lines {
    display: flex;
    flex-direction: row;
  }

  .range-values {
    $common-edges-margin: 65px;
    $values-ended-edges-margin: -30px;
    $padding-bottom: 8px;
    $range-value-height: 24px;
    padding-bottom: $padding-bottom;
    min-height: $range-value-height + $padding-bottom;

    .range {
      position: relative;

      &:first-child {
        margin-left: $common-edges-margin;
      }

      &:last-child {
        margin-right: $common-edges-margin;
      }

      &:not(:last-child) {
        margin-right: 34px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
      }

      @include arrow-color-map($local-colors-lighten);
    }

    &.left-ended {
      .range:first-child {
        margin-left: $values-ended-edges-margin;
      }
    }

    &.right-ended {
      .range:last-child {
        margin-right: $values-ended-edges-margin;
      }
    }

    .range-value {
      $y-padding: 6px;
      text-align: center;
      max-width: 64px;
      max-height: $range-value-height;
      padding-left: $y-padding !important;
      padding-right: $y-padding !important;
      font-size: 10px;
      line-height: 20px;
      mix-blend-mode: normal;
      color: black !important;
      //   color: #262b42;
      font-weight: bold;
      border: 0;
      box-shadow: none;
      &::placeholder {
        color: #3b3c4a !important;
      }

      @include background-color-map($local-colors-lighten);
    }
  }

  .colored-lines {
    .colored-line {
      width: 96px;
      height: 8px;
      border-radius: 1px;

      &:not(:last-child) {
        margin-right: 2px;
      }

      @include background-color-map($local-colors);
    }
  }
}

.colored-line-tooltip {
  .tooltip {
    .tooltip-inner {
      $y-padding: 8px;
      font-size: 14px;
      line-height: 20px;
      padding-bottom: $y-padding;
      padding-top: $y-padding;
      border: 1px solid #b2bfd5;
      background: #ffffff;
      //   color: #404873;
      color: black !important;
    }

    .arrow:before {
      border-bottom-color: #b2bfd5 !important;
    }
  }
}

// .gradesSpacingValue {
//   .numeric-colored-ranges .range-values .range:first-child {
//     margin-left: 16px;
//   }
//   // span.colored-line-tooltip:has(.color-grey) {
//   //   display: none;
//   // }
//   .range-values.right-ended:not(.left-ended) {
//     .range {
//       &:first-child {
//         display: none;
//       }
//       &:nth-child(2) {
//         // margin-left: 16px !important;
//         margin-left: 113px !important;
//       }
//     }
//   }
//   .range-values:not(.left-ended):not(.right-ended) {
//     .range:first-child {
//       margin-left: 114px !important;
//     }
//   }
//   .range-values:not(.left-ended):not(.right-ended),
//   .range-values.left-ended:not(.right-ended) {
//     .range:last-child {
//       display: none;
//     }
//   }
//   .range-values.left-ended.right-ended {
//     .range:nth-child(5) {
//       display: none;
//     }
//   }
// }
