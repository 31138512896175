@import "../../../assets/sass/variables";

.navbar {
  $_bg: #fff;
  $_color: $main-color;
  $_color: var(--main-color);
  background: $_bg;
  color: $_color;
  display: block;
  transition: box-shadow 0.2s $animation;
  will-change: box-shadow;
  // width: 100%;
  z-index: 990;

  .navbar-wrap {
    align-items: center;
    background: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    min-height: inherit;
    padding: 0 1.41429rem;
    position: relative;

    // > * {
    //   margin: 0 $module-rem;
    // }
    .navbar-close {
      color: inherit;
      cursor: pointer;
      font-size: 30px;
      position: absolute;
      right: $module-rem;
      top: $module-rem * 1.5;
      width: auto !important;
      z-index: 1;
    }
    .add-patient {
      height: 100%;
      padding: ($module-rem * 2) ($module-rem * 4);
    }
    .assistant-menu {
      display: block;
      padding: 0 ($module-rem * 4) ($module-rem * 2);

      .link {
        color: inherit;
        display: block;
        font-weight: 700;
        opacity: 0.3;
        padding: ($module-rem / 2) 0;
        transition: opacity 0.2s $animation;

        .link-icon {
          margin-right: $module-rem;
          padding: 0;
          font-size: 1.3em;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .navbar-toggle {
      align-items: center;
      padding: ($module-rem * 4) $module-rem;
      margin-bottom: $module-rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: $horizontalBarHeight;
      justify-content: center;
      width: 100%;
      font-size: 18px;
      color: #c3f2ed;
    }
  }
  .navbar-version-text {
    padding: $module-rem;
    display: flex;
    background-color: $secondary-bg-color;
    justify-content: center;
    width: 100%;
    color: white;
    margin-top: auto !important;
  }
  // &.boxed {
  //   .navbar-wrap {
  //     // max-width: $boxed-width;
  //     // max-width: var(--boxed-width);
  //   }
  // }
  // &.horizontal-vertical {
  //   // @media #{$tabletMediaQuery} {
  //   //   display: flex;
  //   //   justify-content: flex-end;
  //   //   height: 100%;
  //   //   left: 0;
  //   //   position: absolute;
  //   //   top: 0;
  //   //   transform: translateX(-$vertical-navbar-width);
  //   //   transition: transform 0.2s $animation;
  //   //   width: $vertical-navbar-width;
  //   //   will-change: transform, width;
  //   //   z-index: 997;

  //   //   .navbar-wrap {
  //   //     align-items: flex-start;
  //   //     flex-direction: column;
  //   //     height: 100%;
  //   //     justify-content: flex-start;
  //   //     overflow: auto;
  //   //     padding: 0;
  //   //     width: $vertical-navbar-width;

  //   //     > * {
  //   //       margin: 0;
  //   //       width: 100%;
  //   //     }
  //   //   }
  //   //   &.opened {
  //   //     transform: translateX(0);
  //   //   }
  //   // }
  // }
  &.vertical {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    transition: $navbar-toggle-transition;

    width: $vertical-navbar-width-minified;
    will-change: transition, width;
    -webkit-overflow-scrolling: touch;
    z-index: 1000;
    //margin-top: $topNavHeight;
    //padding-top: 4px;
    // border-right: 1px solid $primary-bg-color;

    .navbar-wrap {
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      padding: 0;
      width: $vertical-navbar-width-minified;
      background-color: $primary-bg-color;
      box-shadow: 4px 0px 24px rgba(0, 0, 0, 0.02);
      transition: width 0.2s $animation;

      > * {
        margin: 0;
        width: 100%;
      }
      &-opened {
        width: $vertical-navbar-width;
      }
    }

    &.opened {
      width: $vertical-navbar-width;
      .navbar-version-text {
        background-color: unset !important;
        color: #c3f2ed !important;
      }
      .main-menu .main-menu-wrap .menu-ul .menu-item.active > .item-link,
      a.item-link.active {
        border: none !important;
      }
    }
  }
}

.topNavBar {
  background-color: #008686; //#84c8df;
  z-index: 11;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  height: $topNavHeight;
  padding: 0 20px 0 10px;
  font-size: 12px;
  color: white;
  box-shadow: 0em 0.15em 2em rgba(0, 0, 0, 0.15);

  .logo {
    padding: 3px 0px;
    min-height: 100%;
    height: 100%;
    .logo-img {
      height: 33px;
      padding: 2px;
      border-radius: 4px;
    }
  }
  span {
    font-size: 14px;
    // font-weight: bold;
  }
  a.link-color {
    text-decoration: underline !important;
  }
}
