.AddRemoveWatchlistPopup {
  & > .ant-modal-content {
    & > .ant-modal-body {
      padding-bottom: 0px;
      & > .newWatchListFormContainer {
        & > .form-group {
          margin-bottom: 0px;
        }
        & > .ant-btn {
          margin-top: auto;
          margin-bottom: 1.1px;
        }
      }

      & > .watchlistArrayContainer {
        margin-top: 1.5rem;
        & > .ant-list {
          max-height: 230px;
          overflow: auto;
          padding: 0px;
          margin: 0px -1.5rem;
          padding-bottom: 1.5rem;
          ul.ant-list-items > .ant-list-item {
            padding: 15px 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &:hover {
              background: rgba(23, 169, 154, 0.2);
            }
          }
        }
      }
    }
  }
}
