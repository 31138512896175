$error-color: #ea5455;
.loginForm {
  .error-border {
    border: 1px solid $error-color !important;
    border-radius: 8px;
  }
  .login-password {
    .ant-input-affix-wrapper .ant-input:not(textarea):first-child {
      padding: 0 calc(1.53846rem - 0.2em) !important;
    }
    .ant-input-affix-wrapper {
      padding-inline: 0px !important;
      background-color: transparent !important;
      // .ant-input {
      // }
      .ant-input-suffix {
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }
}
