.rating-container {
  background-color: #f9f9f9;
  border: 1px solid #e0e9ee;
  display: flex;
  align-items: center;
  column-gap: 0px !important;
  height: 36px !important;
  min-width: max-content;
  font-weight: bold;
  border-radius: 3px;
  width: max-content;
  margin: auto;

  & > div:first-child {
    @extend .adjust-full-space;
    font-size: 13px !important;
    border-right: 1px solid #e0e9ee;
    padding: 0px 5px;
    min-width: 60px !important;
  }
  & > div:nth-child(2) {
    @extend .adjust-full-space;
    font-size: 1.2rem;
    font-weight: bolder;
    min-width: 25px !important;
  }
  & > div:last-child {
    @extend .adjust-full-space;
    // background-color: #ffc000;
    color: white;
    padding: 0px 5px;
    height: 100% !important;
  }
}
.parentNode.expanded-row .rating-container {
  border: 0px !important;
  & > div:last-child {
    height: 36px !important;
  }
}

.adjust-full-space {
  height: 100% !important;
  display: grid;
  place-items: center;
  min-width: 30px;
  text-align: center;
}
