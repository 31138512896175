.bg_white>.ant-select-selector {
	background-color: #fff !important;
}

.audit_logs_listing_table {
	.affected_item_wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.affected_item_action_button {
		display: inline;

		vertical-align: middle;
		box-sizing: border-box;
		background: transparent;
		font-size: 14px;
		margin-right: 18px;

		box-shadow: none;
		font-weight: unset;
		transition: all 0.1s ease-in-out;
	}
}

.change_properties_table {
	margin: 11px;
	margin-top: 20px;

	.ant-table-body {
		max-height: 300px !important;
		height: auto;
	}
}

.text_container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
	text-align: left;

	.item_title {
		font-size: 16px;
		width: 80px;
		font-weight: 600;
	}

	.item_value {
		font-size: 15px;
		width: calc(100% - 100px);
	}
}

.audit_more_or_less {
	white-space: normal;
}

.audit_log_filters {

	.ant-select,
	.ant-picker {
		height: 45px !important;

		.ant-select-selector {
			height: 45px !important;
		}
	}

	.ant-input-affix-wrapper {

		.ant-input:not(:last-child) {
			padding-right: 2px !important;
		}

		.ant-input-suffix {
			padding-left: 10px !important;
		}
	}

	.ant-select-selection-item {
		display: block !important;
		padding-right: 50px !important;
		margin: auto !important;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.audit_filter_btn_container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 25px;
		gap: 10px;
	}
}