@import "../../../../../assets/sass/variables";

.tableTree .ant-table-wrapper {
  tr td {
    box-shadow: none !important;
    border: 0px !important;
    margin: 0px;
  }
  .ant-table-tbody
    > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected):hover
    > td {
    box-shadow: none !important;
  }
}

.tableTree {
  .ant-table-content {
    overflow: hidden;
    border: 1px solid #b6b6b6;
    border-radius: 10px;
  }
  .ant-table-tbody
    > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected):hover
    > td.TableTreeNameCol {
    border-color: #aebcc3 !important;
  }
  table {
    border-collapse: separate;
  }
  .ant-table-thead tr th {
    border-bottom: 0px !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    background-color: inherit !important;
  }
  margin-top: 10px;

  overflow: hidden;
  .ant-table-thead tr {
    background-color: #2b3b4a;
    .TableTreeNameCol {
      color: #fff;
      background-color: #2b3b4a;
    }
    .PreviousRatingCol,
    .CurrentRatingCol,
    .PredictedRatingCol,
    .notification-column {
      color: #fff;
    }
  }
  .tableArrowContainer.close .tableArrow {
    transform: rotate(-90deg);
  }
  .tableArrowContainer.open .tableArrow {
    transform: rotate(0deg);
  }
  .tableArrowContainer::before {
    position: absolute;
    left: 50%;
    top: 0%;
    width: 0px;
    height: 100%;
    border-left: 1px dashed red;
    content: "";
    transform: translateX(-50%);
  }
  .tableArrow {
    transition: 0.05s linear;
    display: inline-flex;
    height: 24px;
    width: 24px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    background: #ffffff;
  }
  .tableArrowContainer {
    position: absolute;
    top: -2px;
    width: 50px;
    height: calc(100% + 5px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
  }
  .ant-table-row:last-child .tableArrowContainer {
    height: 100%;
  }
  .ant-table-row {
    .tableArrowContainer::after {
      left: 0;
      position: absolute;
      height: 100%;
      width: 50px;
      top: 0px;
      content: "";
      z-index: 1;
    }

    .TableTreeNameCol {
      padding-left: 50px !important;
      border-right: 1px solid #aebcc3 !important;
    }
  }
  .parentNode {
    td {
      border-top: 0px !important;
    }
    .TableTreeNameCol {
      padding-left: 55px !important;
    }
    .tableArrowContainer {
      left: 0px !important;
      border-bottom: 0px solid #b6b6b6;
      border-right: 1px solid #b6b6b6;
      height: 100%;
    }
    .tableArrowContainer.close {
      background-color: #f0f0f0;
    }
    .tableArrowContainer::after {
      width: 0px;
    }
    .tableArrow svg path {
      fill: #2b3b4a !important;
    }
  }
  .parentNode:not(.expanded-row) td {
    border-top: 1px solid #b6b6b6 !important;
  }
  .parentNode.expanded-row {
    background-color: #20686d;
    .NoDataCol {
      color: white;
    }
    .TableTreeNameCol * {
      color: white;
    }
    .tableArrow svg path {
      fill: #20686d;
    }
    .tableArrowContainer {
      border-right: 0px solid #b6b6b6;
      border-left: 0px solid #b6b6b6;
    }
    .TableTreeNameCol {
      border-right: 0px !important;
    }
  }

  .companyNode {
    background-color: #f1fbff;
    .tableArrow {
      background-color: #3690ff;
      svg path {
        fill: #f7fbfd;
      }
    }
    .tableArrowContainer::after {
      background-color: #20686d;
      left: -50px;
      width: 50px;
    }
  }
  .row-indent-1 .tableArrowContainer {
    left: 50px;
  }
  .siteNode {
    background-color: #e3f2f6;
    .tableArrow {
      background-color: #006699;
      svg path {
        fill: #f7fbfd;
      }
    }
    .tableArrowContainer::after {
      background-image: linear-gradient(90deg, #20686d 50px, #f1fbff 50px);
      left: -100px;
      width: 100px;
    }
  }
  .row-indent-2 .tableArrowContainer {
    left: 100px;
  }
  .categoryNode {
    background-color: #e3f2f6;

    .tableArrow {
      background-color: #8f23b5;
      svg path {
        fill: #f7fbfd;
      }
    }
    .tableArrowContainer::after {
      background-image: linear-gradient(90deg, #20686d 50px, #f1fbff 50px);
      left: -150px;
      width: 150px;
      border-right: 50px solid #e3f2f6;
    }
  }
  .row-indent-3 .tableArrowContainer {
    left: 150px;
  }
  .subcategoryNode {
    background-color: #e3f2f6;

    .tableArrow {
      background-color: #e06a14;
      svg path {
        fill: #f7fbfd;
      }
    }
    .tableArrowContainer::after {
      background-image: linear-gradient(90deg, #20686d 50px, #f1fbff 50px);
      left: -200px;
      width: 200px;
      border-right: 100px solid #e3f2f6;
    }
  }
  .row-indent-4 .tableArrowContainer {
    left: 200px;
  }
  .trackedItemNode {
    background-color: #e3f2f6;
    .tableArrowContainer::after {
      background-image: linear-gradient(90deg, #20686d 50px, #f1fbff 50px);
      left: -50px;
      width: 250px;
      border-right: 150px solid #e3f2f6;
    }
    .tableArrowContainer .tableArrow {
      display: none;
    }
  }

  .ant-table-tbody tr:not(.parentNode) td {
    border-top: 1px dashed #aebcc3 !important;
  }

  .parentNode:not(.expanded-row) {
    background-color: #ffffff;
  }
  .tableArrowContainer::after {
    display: none !important;
  }
  .rowColorBoxcontainer {
    position: absolute;
    display: inline-flex;
    width: auto;
    height: 100%;
    top: 0px;
    position: absolute;
    left: 0px;
  }
  .row-box {
    display: none;
    position: relative;
    width: 50px;
    margin-top: -2px;
    height: calc(100% + 2px);
  }
  .row-box.groupNodeBox {
    height: calc(100% + 3px);
  }
  .groupNodeBox {
    background-color: #20686d;
  }

  .companyNodeBox {
    background-color: #f1fbff;
  }

  .siteNodeBox {
    background-color: #e3f2f6;
  }
  .categoryNodeBox {
    background-color: #e3f2f6;
  }

  .subcategoryNodeBox {
    background-color: #e3f2f6;
  }
  .trackedItemNodeBox {
    background-color: #e3f2f6;
  }
  .companyNode .groupNodeBox {
    display: block;
  }
  .siteNode {
    .groupNodeBox,
    .companyNodeBox {
      display: block;
    }
  }
  .categoryNode {
    .groupNodeBox,
    .companyNodeBox,
    .siteNodeBox {
      display: block;
    }
  }
  .subcategoryNode {
    .groupNodeBox,
    .companyNodeBox,
    .siteNodeBox,
    .categoryNodeBox {
      display: block;
    }
  }
  .trackedItemNode {
    .groupNodeBox,
    .companyNodeBox,
    .siteNodeBox,
    .categoryNodeBox,
    .subcategoryNodeBox,
    .trackedItemNodeBox {
      display: block;
    }
  }

  .row-box::before {
    position: absolute;
    left: 50%;
    top: 0%;
    width: 0px;
    height: 100%;
    content: "";
    transform: translateX(-50%);
  }

  .companyNodeBox::before {
    border-left: 1px dashed #3690ff;
  }
  .siteNodeBox::before {
    border-left: 1px dashed #006699;
  }
  .categoryNodeBox::before {
    border-left: 1px dashed #8f23b5;
  }
  .subcategoryNodeBox::before {
    border-left: 1px dashed #e06a14;
  }

  .parentNode .tableArrowContainer::before {
    content: none;
  }

  .companyNode .tableArrowContainer::before {
    border-color: #3690ff;
  }
  .siteNode .tableArrowContainer::before {
    border-color: #006699;
  }
  .categoryNode .tableArrowContainer::before {
    border-color: #8f23b5;
  }
  .subcategoryNode .tableArrowContainer::before {
    border-color: #e06a14;
  }
  .trackedItemNode .tableArrowContainer::before {
    border-color: #3690ff;
  }

  .trackedItemNodeBox::before {
    border-left: 1px solid #149ebc;
  }
  .trackedItemNodeBox::after {
    border-top: 1px solid #149ebc;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 0px;
    position: absolute;
    content: "";
  }

  tr:not(.expanded-row.parentNode) td:not(.TableTreeNameCol) {
    background-color: #f8fdff !important;
  }
  .ant-table-row.expanded-row + .ant-table-row {
    .tableArrowContainer::before {
      top: 60%;
      height: 40%;
    }
  }
  .ant-table-row.expanded-row + .ant-table-row {
    .trackedItemNodeBox::before {
      top: 70%;
      height: 40%;
    }
  }
  .ant-table-row.expanded-row + .ant-table-row.lastSibling {
    .trackedItemNodeBox::before {
      height: 0%;
    }
  }
  .ant-table-row.expanded-row
    + .ant-table-row:not(.lastSibling)
    .trackedItemNodeBox::before {
    top: 50%;
    height: 50%;
  }
  .ant-table-row.lastSibling {
    .trackedItemNodeBox::before {
      top: 0%;
      height: 50%;
    }
  }
  .tableArrow .ant-spin-spinning {
    margin-left: 0px;
  }

  .parentNode .tableArrow {
    background-color: #fff;
  }
  .rowColorBoxcontainer > span:first-child {
    background-color: #20686d;
    &::before {
      content: none;
    }
  }

  .ant-table-row:last-child {
    border-bottom: 0px;
  }

  .parentNode.expanded-row + .ant-table-row td {
    border-top: 0px !important;
  }

  .ant-table-row.expanded-row
    + .ant-table-row.ant-table-row.lastSibling
    .tableArrowContainer.close::before {
    display: none;
  }
  .ant-table-row.lastSibling .tableArrowContainer.close::before {
    top: 0%;
    height: 50%;
  }
  .ant-table-measure-row {
    border: 0px !important;
    display: none;
  }
  .ant-table-row.parentNode {
    border: 0px !important;
  }
  .ant-table-tbody
    > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected):hover
    > td {
    border-color: #aebcc3 !important;
  }
  .ant-table-wrapper .ant-table-tbody tr {
    transform: scale(1);
    position: relative;
    z-index: 0 !important;
  }

  .ant-table-wrapper .ant-table-tbody tr.parentNode {
    transform: scale(1);
    position: relative;
    z-index: 1 !important;
  }
  .parentNode:not(.expanded-row) .tableArrowContainer {
    top: 0px;
  }
  .parentNode.expanded-row .tableArrowContainer {
    top: 0px;
  }
  .treeNameColumn {
    display: flex;
    align-items: center;
    padding-right: 15px;
    .dottedActionsButton .ant-btn.ant-btn-primary:not(:disabled) {
      margin: 0;
    }
    .roadmapActions {
      display: inline-flex;
      align-items: center;
      margin: 0 10px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
