.numeric-input-group {
    display: inline-block;

    input {
        width: 100% !important;
        display: inline;
    }

    input:focus + i {
        z-index: 3;
    }

    i {
        position: absolute;
        margin-left: -25px;
        margin-top: 4px;
        opacity: 0.4;
    }

    .form-control {
        border-radius: 0.25rem !important;
    }
}
