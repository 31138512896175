.notification-column {
  .notification-col-wrapper {
    display: flex;
    align-items: center;
    .exclamation-icon {
      position: absolute;
      left: 19px;
      top: 12px;
    }
    .notification-column-container {
      background-color: yellow;
      padding: 5px 15px;
      width: max-content;
      display: flex;
      margin: auto;
      border-radius: 3px;
      border: 1px solid yellow;
      svg path {
        stroke: inherit;
      }
      & > .action-badge .ant-badge-count {
        padding: 0px;
        height: 18px !important;
        min-width: 18px !important;
        width: 18px !important;
        line-height: 18px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8.5px !important;
        background-color: white;
        font-weight: bold;
        top: 5px;
        right: 5px;
        & > .ant-scroll-number-only {
          height: 100%;
        }
      }
    }
    .notification-column-container:hover {
      svg,
      path {
        fill: inherit;
        stroke: inherit;
      }
    }
  }
  padding-right: 0.76923rem !important;
  @media (max-width: 1300px) {
    .exclamation-icon {
      left: 14px !important;
    }
  }
}
.parentNode.expanded-row .notification-column {
  .notification-column-container {
    svg,
    path {
      stroke: white;
    }
  }
  .notification-column-container:hover {
    svg,
    path {
      fill: white;
      stroke: white;
    }
  }
}

@media (min-width: 1632px) {
  .exclamation-icon {
    left: 24px !important;
  }
}
@media (min-width: 1845px) {
  .exclamation-icon {
    left: 32px !important;
  }
}
@media (min-width: 1973px) {
  .exclamation-icon {
    left: 38px !important;
  }
}
@media (min-width: 2229px) {
  .exclamation-icon {
    left: 46px !important;
  }
}
@media (min-width: 2998px) {
  .exclamation-icon {
    left: 74px !important;
  }
}
