.CreateWatchlistPopup {
  .ant-modal-header {
    .ant-modal-title h5 {
      padding-left: 1.5rem !important;
    }
  }
  .ant-modal-body {
    padding-bottom: 0px;
  }
}

.newWatchListFormContainer {
  display: flex;
  justify-content: center;
  //  align-items: center;
  column-gap: 10px;
  & > .form-group {
    flex-grow: 1;
    margin-bottom: 1.95rem;
  }
  & > .ant-btn {
    margin-right: 0px;
    margin-top: 28px;
  }
}
