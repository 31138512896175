.category-container {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  // min-width: max-content;
  // max-width: max-content;
  margin: auto;
  column-gap: 10px;
  min-width: 0px;
  max-width: 100%;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  &>div{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.category-popover-container {
  .ant-popover-content {
    & > .ant-popover-inner {
      border-radius: 6px;
      & > .ant-popover-inner-content {
        text-align: center;
        padding: 5px 10px;
        & > div:first-child {
          color: black;
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
  }
  &.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-rightBottom
    > .ant-popover-content
    > .ant-popover-arrow {
    left: 2px;
    background-color: white;
    width: 15px;
    height: 15px;
  }
}
