@import "../../../../../../assets/sass/variables";

.watchlist-tabs-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;

  & > .ant-tabs.ant-tabs-top {
    margin-right: auto;
    & > .ant-tabs-nav {
      margin: 0px !important;
      & > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
        margin-top: 5px;
        & > .ant-tabs-tab {
          min-width: 162px;
          width: auto;
          & > .ant-tabs-tab-btn {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  & > .ant-btn.ant-btn-primary {
    border: 1px solid $primary-bg-color;
    // &:hover,
    // &:focus,
    // &:active {
    //   border: none;
    // }
  }
}
.anticon.watchlistRenameBtn svg path {
  fill: #000000;
}
