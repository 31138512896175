.watchListCardContainerClass {
  & > h6 {
    margin-bottom: 3px;
    margin-top: 0px;
  }

  & > .watchlistCard {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px 15px;
    font-size: 21px;
    & > .ant-card-body {
      padding: 0px !important;
      box-shadow: 0px 0px 0px 0.5px lightgray;
      min-width: 60px;
      width: auto;
      text-align: center;

      & > .watchlistStatus {
        color: black;
        margin: 0px;
        padding: 6px 12px;
      }
      & > .watchlistRating {
        color: white;
        margin: 0px;
        padding: 2px;
        font-size: clamp(10px, 1vw, 18px);
      }
      & > .watchlistDays {
        text-align: center;
      }
    }
  }
}
