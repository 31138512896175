@import "../../../assets/sass/variables";

$_accent-palette: accent-palette(500);
$_animation: $animation;

//item
$_item-color: #c3f2ed;
$_item-hover-color: #1890ff;
$_item-active-color: #ffffff;
$_item-group-color: $primary-bg-color;
$_item-bg: transparent;
$_item-group-title-bg: #ffffff;
$_item-border: #f2f2f2;
$_item-active-border: #1890ff;
// $_item-hover-bg: #2f89fc;
$_item-hover-bg: #e6f7ff;
$_item-active-bg: $secondary-bg-color;
$_item-group-bg-compress: main-palette(200);
$_item-h-indents: 0;

//separator
$_separator: main-palette(300);

//icon
$_icon-color: inherit;
$_icon-hover-color: inherit;
$_icon-active-color: accent-palette(500);
$_icon-br: 50%;

//badge
$_badge-color: rgba(accent-palette(500), 0.2);
$_badge-bg: accent-palette(500);

//sub
$_sub-bg: transparent;
$_sub-separator: main-palette(300);

.main-menu {
  display: block;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: inherit;
  line-height: 20px;

  .main-menu-wrap {
    width: inherit;

    .menu-ul {
      list-style-type: none;
      margin: 0;
      padding: 0; //($module-rem * 2); //($module-rem * 3) 0 ($module-rem * 2) 0;

      .menu-item {
        font-weight: 700;
        position: relative;
        .group-title {
          background: $_item-group-title-bg;
          color: $_item-group-color;
          text-align: center;
          display: block;
          padding: ($module-rem / 6);
          // margin: ($module-rem / 2) 0;
          text-transform: uppercase;
        }

        .item-link {
          // align-items: center;
          background: $_item-bg;
          color: $_item-color;
          cursor: pointer;
          display: flex;
          height: 100%;
          justify-content: center;
          min-height: ($module-rem * 3);
          padding: ($module-rem * 1.2); // ($module-rem * );
          font-size: 0.9rem;
          position: relative;
          text-decoration: none;
          transition: background 0.2s $_animation, color 0.2s $_animation;
          svg {
            fill: none;
            stroke: #c3f2ed;
          }
          &.active {
            color: $_item-active-color;
            background: $_item-active-bg;
            border-bottom: 0.3px solid $_item-border;
            border-top: 0.3px solid $_item-border;
            // border-right: 3px solid $_item-active-border;

            svg {
              fill: white;
              stroke: white;
            }
          }
          .link-text {
            flex-grow: 2;
            flex-shrink: 1;
            margin: 0 ($module-rem / 2);
            position: absolute;
            left: 19%;
            max-width: 80%;
          }

          .tree-item-text {
            flex-grow: 2;
            flex-shrink: 1;
            margin: 0 ($module-rem / 2);
            position: absolute;
            left: 19%;
            max-width: 100%;
          }

          .link-badge {
            margin: 0 ($module-rem / 2);
          }
          .link-icon {
            margin: 0 ($module-rem / 2);
            opacity: 0.25;
            padding: 0;
            font-size: 1.3em;
            transition: none;
          }
          .link-caret {
            margin: 0 ($module-rem / 2);
            transition: transform 0.1s $_animation;
          }
          // &:hover {
          //   font-size: 14.5px;
          // }
        }
        .sub {
          background: $_sub-bg;
          list-style-type: none;
          overflow: hidden;
          margin: 0;
          padding: 0;

          .menu-item {
            //padding-left: $module-rem / 2;
            //padding-right: $module-rem / 2;

            &:after {
              display: none;
            }
          }
        }
        &.active {
          &:after {
            transform: translateX(4px);
            transition: 0.2s transform 0.2s $_animation;
          }
          > .item-link {
            background: $_item-active-bg;
            color: $_item-active-color;
            border-bottom: 0.3px solid $_item-border;
            border-top: 0.3px solid $_item-border;

            .link-caret {
              transform: rotate(90deg);
            }
          }
        }
        &.has-sub {
          position: relative;
          color: white;

          > .item-link {
            position: relative;

            .caret {
              display: block;
            }
          }
          &.active {
            > .item-link {
              .caret {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
  &.horizontal {
    @media #{$min992} {
      margin: 0 !important;
      overflow: visible;
    }

    .main-menu-wrap {
      @media #{$min992} {
        .mCustomScrollBox {
          overflow: visible;

          .mCSB_container {
            overflow: visible;
          }
        }
        .mCSB_scrollTools {
          display: none !important;
        }
        .menu-ul {
          display: flex;
          flex-wrap: wrap;
          padding: 0;

          > .menu-item {
            > .group-title {
              display: none;
            }
          }
          .menu-item {
            padding: 0;

            .item-link {
              padding: $module-rem $module-rem;

              .link-caret {
                transform: rotate(90deg);
              }
            }
            .sub {
              background: #fff;
              border-radius: $shape;
              box-shadow: $shadow;
              display: block !important;
              color: #000;
              height: auto !important;
              left: 0;
              opacity: 0;
              padding: $module-rem 0;
              position: absolute;
              transition: 0.2s opacity 0s $animation,
                0s visibility 0.2s $animation;
              visibility: hidden !important;
              width: $module-rem * 25;
              z-index: 500;

              &:before {
                bottom: ($module-rem * 4);
              }
              .menu-item {
                width: 100%;

                .item-link {
                  height: auto;
                  min-height: initial;
                  padding-top: $module-rem / 2;
                  padding-bottom: $module-rem / 2;
                }
              }
            }
            &:hover {
              > .sub {
                opacity: 1;
                transition: 0.2s opacity 0s $animation,
                  0s visibility 0s $animation;
                visibility: visible !important;
              }
            }
            &:last-child {
              .sub {
                left: auto;
                right: 0;
              }
            }
            &.full-sub {
              position: static;

              .sub {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.menuFoldOutlineIcon {
  margin-bottom: 60px;
}

.menuDelphiLogo {
  margin-top: 75px;
  margin-right: 112px;
}

.notification-count-badge {
  .ant-badge-count {
    box-shadow: none !important;
    height: 17px !important;
    font-size: 10px !important;
    background-color: #ed5564;
    top: -2px !important;
  }
}
