@import "../../../assets/sass/variables";

.actions {
  align-items: center;
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  margin: 0 !important;

  .item {
    margin: 0 $module-rem;
    cursor: pointer;

    .icon {
      &.log-out {
        margin: 0 !important;
        margin-left: auto !important;
        opacity: 0.2;
        color: #000 !important;
      }
    }
    .tc-badge {
      justify-content: center;
      font-size: 0.8em;
      min-width: 18px;
      height: 18px;
      padding-left: 2px;
      padding-right: 2px;
      position: absolute;
      right: -5px;
      text-align: center;
      top: -5px;
    }
    &:hover {
      .tc-icon {
        opacity: 1;
      }
    }
    &.active {
      .tc-icon {
        color: accent-palette(500);
        opacity: 1;
      }
    }
  }
}

.dropdown-action-container {
  // left: 938px;
  .ant-dropdown-menu {
    margin-top: -1px;
    padding: unset;
    border-radius: 6px;
    border: 2px solid $primary-bg-color;
    & .ant-dropdown-menu-item {
      &:not(:last-child) {
        border-bottom: 1px solid lightgray;
      }
      &:first-child:hover {
        border-radius: 6px 6px 0 0;
      }
      &:last-child:hover {
        border-radius: 0 0 6px 6px;
      }
      &:hover {
        svg {
          fill: $primary-bg-color;
          path {
            fill: $primary-bg-color;
          }
        }
        span {
          color: $primary-bg-color;
        }
      }
      svg {
        fill: #6e6b7b;
        path {
          fill: #6e6b7b;
        }
      }
    }
    .disable_menu_item,
    .disable_menu_item:hover {
      background-color: whitesmoke !important;
      span {
        color: grey !important ;
      }
      svg {
        fill: #6e6b7b;
        path {
          fill: #6e6b7b;
        }
      }
    }
  }
  .actionsButtonsDropdown {
    .ant-dropdown-menu-title-content {
      padding: $module-rem;
      .rating-graph-btn {
        svg {
          fill: transparent !important;
          path {
            fill: transparent !important;
            stroke: #6e6b7b !important;
          }
        }
      }
      &:hover {
        .rating-graph-btn {
          svg {
            // fill: $primary-bg-color !important;
            path {
              stroke: $primary-bg-color !important;
            }
          }
        }
      }
    }
  }
}
.dashed-action-btn {
  .anticon-dash {
    padding: unset;
  }
}

.item-link {
  transition: color 0.2s $animation;

  &:hover {
    color: main-palette(500);
  }
}

.notification-icon {
  margin-right: 0.2rem;
  &:hover {
    cursor: pointer;
    svg {
      fill: $primary-bg-color;
    }
  }
}

.dropdown-link-wrap {
  position: relative;
}

.dropdown-title {
  font-size: 1.1em;
  margin: 0;
  padding: $module-rem ($module-rem * 2);
}

.action-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
}
.action-badge {
  .ant-badge-count {
    background: $error-color;
  }
}
.action-item {
  > a {
    align-items: center;
  }

  .icon {
    border-radius: 50%;
    background: #e8e8e8;
    color: inherit;
    font-size: 1rem;
    padding: $module-rem;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    width: auto;
  }

  .text {
    display: inline-flex;
    flex-direction: column;
    color: black;
    font-size: 0.94em;
    transition: color 0.2s ease-in-out;
    width: 250px;

    .sub-text {
      color: #6e6b7b;
      font-size: 0.8em;
      margin-top: $module-rem / 4;
      line-height: 1;
      display: inline-flex;
      justify-content: space-between;
    }
  }
}

.customIcons {
  font-size: x-small;
  color: #6e6b7b;
}

.dropdown-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ($module-rem/3) ($module-rem * 2);

  .dropdown-title {
    font-size: 1.25rem;
    font-weight: bolder;
  }

  > * {
    padding: 0;
  }
}

.dropdown-actions {
  padding: $module-rem ($module-rem * 3) 0;
  width: 100%;
}

.ant-badge-count {
  box-shadow: 0 0 0 2px #fff !important;
  height: 18px;
  min-width: 18px;
}

.empty-item {
  display: block;
  padding: $module-rem ($module-rem * 2);
  margin: auto;
  font-style: italic;
}

.ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0 $module-rem;
  // border-bottom: 1px solid #0302290d !important;
  &:hover {
    background-color: #17a99a1a !important;
  }
}
.ant-dropdown-menu-title-content {
  padding: ($module-rem/1.5) $module-rem;
}

.account-menu .ant-dropdown-menu-item {
  font-size: 14px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
