@import "../../assets/sass/variables";

.public-layout {
  background-size: cover;
  background-position: center center;
  display: inline-block;
  height: 100%;
  overflow: auto;
  width: 100%;

  .content-box {
    background: #fff;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    max-width: 430px;
    // min-height: 54%;
    margin-top: 9%;
    margin-left: 6%;
    border-radius: 7px;
    min-width: 430px;
    float: left;

    &.transparent {
      background: rgba(#fff, 0.9);
    }

    .content-header {
      padding-top: $module-rem * 2;
    }

    .content-body {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-grow: 1;
      padding: $module-rem ($module-rem * 4);
      width: 100%;
      margin-bottom: 20px;

      > * {
        width: 100%;
      }
    }
  }

  .login-text-detail {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: $module-rem ($module-rem * 4);
    margin-top: 15%;
    // width: 510px;
    margin-left: 3%;
    float: left;
  }
  p.login-detail-text {
    font-size: 1.8rem;
    line-height: 35px;
    > :first-child,
    :last-child {
      font-size: 35px;
    }
    > :first-child {
      color: #336589;
    }
    > :last-child {
      color: #e68a27;
      letter-spacing: 1.4px;
    }
  }
}

@media #{$max600} {
  .public-layout {
    background-position: 30%;

    .content-box {
      margin: 10%;
      min-width: 80%;
      max-height: fit-content;
    }

    .login-text-detail {
      width: fit-content;
      margin-top: 0 !important;
    }
  }
}

@media only screen and (min-height: #{$minTabletHeight}) and (max-width: #{$maxTabletWidth}) {
  .public-layout {
    background-position: 20%;
    .content-box {
      margin-top: 18%;
      margin-left: 12%;
      min-width: 60%;
    }

    .login-text-detail {
      position: fixed;
      bottom: 20%;
      margin-left: 9%;
    }
  }
}

.loading-animation-wrapper {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  z-index: 1000000;

  .loading-animation {
    position: absolute;
    left: 50%;
    top: 50%;

    height: 50px;
    width: 50px;
  }
}
