.rating-container {
  background-color: #f9f9f9;
  border: 1px solid #e0e9ee;
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 35px;

  min-width: max-content;
  max-width: max-content;
  margin: auto;
  font-weight: bold;
  border-radius: 3px;

  & > div:first-child {
    @extend .adjust-full-space;
    font-size: 0.9rem;
    border-right: 1px solid #e0e9ee;
    padding: 0px 5px;
    min-width: 84.34px;
  }
  & > div:nth-child(2) {
    font-size: 1.2rem;
    font-weight: bolder;
    min-width: 32px;
  }
  & > div:last-child {
    @extend .adjust-full-space;
    // background-color: #ffc000;
    color: white;
    height: 34px !important;
    padding: 0px 5px;
    min-width: 60px;
    border-radius: 0px 3px 3px 0px;
  }
}

.adjust-full-space {
  height: 100%;
  display: grid;
  place-items: center;
}
