@import "assets/sass/styles";
@import "antd/dist/antd.css";

$error-color: #ea5455;

#app {
  table,
  span:not(.anticon):not([class*=" icofont-"]):not([class^="icofont-"]),
  td,
  div,
  p,
  h1,
  h2,
  h3,
  h4,
  ol,
  li,
  ul {
    font-family: $main-ff, sans-serif !important;
  }
}

body {
  font-family: $main-ff, sans-serif !important;
}

.input-error {
  border-color: $error-color !important;
  background-color: #ea54551a !important;
}

.disabled-btn {
  cursor: not-allowed;
}

.error {
  color: $error-color;
  font-size: 12px;
  margin-top: 0.5rem;
}

.ant-input-number-input {
  padding: 0;
}

.pointer-event-none {
  pointer-events: none;
}

.collpase-menu-parent {
  height: 350px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: #f7f7f7;

  .ant-collapse-content-active {
    max-height: 262px !important; //110
    height: max-content !important; //110
  }

  .ant-collapse-content-box {
    height: max-content;
    max-height: inherit;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 10px 16px !important;

  .ant-collapse-arrow {
    vertical-align: -2px !important;

    svg {
      width: 15px;
      height: 15px;
      padding: 2px;
      background: #fff;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
    }
  }

  &:hover,
  &:focus {
    .ant-collapse-arrow svg {
      color: #40a9ff;
      border-color: #40a9ff;
    }
  }
}

.action-btn-right {
  float: right;
}

.bold-text {
  font-weight: bold;
}

.bold-text-color {
  font-weight: bold;
  color: #000;
}

.add-company {
  margin-bottom: 20px;
  float: right;
}

.ant-btn {
  margin-right: 10px;
}

.ant-switch {
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0 !important;

  &.ant-switch-checked {
    &.disableSwitch {
      // background-color: grey !important;
      opacity: 40%;
      pointer-events: none;
    }

    background-color: #02b9a3 !important;
    border: none;
    mix-blend-mode: normal;

    .ant-switch-handle::before {
      background-color: white;
    }
  }

  $width: 2.3rem;
  $height: $width / 2;
  background: #e4e4e4;
  margin-left: 5px;
  width: $width;
  min-width: $width;
  height: $height;
  margin: auto 0.35714rem !important;

  .ant-switch-handle {
    width: $height/1.3;
    height: $height/1.3;

    &::before {
      background-color: #02b9a3;
    }
  }
}

.ant-btn.ant-btn-circle {
  height: 37px;
  width: 37px;
}

.company-logo {
  display: flex;
  height: 40px;
  font-size: 18px;
  color: #5e5873;
}

.company-logo .logo {
  width: 40px;
  float: left;
  margin-right: 2%;
}

@media only screen and (min-height: #{$minTabletHeight}) and (max-width: #{$maxTabletWidth}) {
  .box-section {
    width: -webkit-fill-available;
    margin-left: 10%;
    margin-right: 10%;
  }
}

.same-address-checkbox {
  position: absolute;
  top: -45px;
  left: -2px;
}

.user-role {
  position: fixed;
  right: 37px;
  top: 74px;
  font-size: 12px;
  font-weight: bold;
  color: $primary-bg-color;
}

.success-box {
  margin-bottom: 5px;
  background: mediumseagreen;
  padding: 9px;
  color: white;
  // text-align: center;
  border-radius: 3px;
}

.site-tiles {
  padding: 10px;
  background: gainsboro;
  border-radius: 5px;
  text-align: center;
  margin: 10px 0px;
  cursor: pointer;
}

.menu-simple-default {
  color: black !important;
}

.menu-simple-default:hover {
  color: #336cfb !important;
}

.menu-simple-selected {
  color: #336cfb !important;
}

.layout.vertical .app-container .footer {
  left: 0 !important;
}

.site-tiles:hover {
  padding: 10px;
  background: gray;
  color: white;
  border-radius: 5px;
  text-align: center;
  margin: 10px 0px;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .user-role {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .reports-bottom-section {
    // width: 125% !important;
    padding-right: 6px !important;
  }
}

.example {
  position: fixed;
  top: 500px;
  left: 300px;
}

.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}

.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.ant-collapse-content {
  line-height: 3 !important;
  max-height: 80px !important;
  overflow-y: auto !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.registered-activities-dropdown {
  .ant-select-selector {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    max-width: 100% !important;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

.require-ant-select {
  .ant-select-selector {
    border-color: $error-color !important;
    background-color: #ea54551a !important;
  }
}

.table-row-light {
  background-color: #ebeaf3;
}

.table-row-dark {
  background-color: #c9c8d3;
}

.filterOverlayWrapper {
  padding-top: 9px;
  margin-left: -10px;

  & > .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border: none !important;
      border-radius: 6px !important;

      .ant-popover-inner-content {
        padding: 0px;

        .filtersMenuDropdown {
          padding: 2.1rem 1rem 1.5rem;
          border-radius: 6px;
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
          border: 1px solid #f0f0f0;
        }
      }
    }
  }
}

//////////////////////////////////////Listing Tabs////////////////////////////////////////////

.ant-tabs {
  font-size: 13px;

  .ant-tabs-nav {
    .ant-tabs-tab {
      padding: 10px;
      width: 162px;
      box-sizing: border-box;
      font-size: 12px;
      border-radius: 3px;
      text-align: center;
      display: flex;
      justify-content: center;
      max-height: 28px;

      &:not(:first-child) {
        margin-left: 10px !important;
      }

      .ant-tabs-tab-btn {
        color: unset;
      }

      &:hover,
      &.ant-tabs-tab-active {
        background-color: $primary-bg-color;
        color: $primary-txt-color;
        border: 1px solid $primary-bg-color;
      }

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: unset;
      }
    }

    .ant-tabs-ink-bar {
      &.ant-tabs-ink-bar-animated {
        left: unset !important;
        width: unset !important;
      }
    }
  }
}

.ant-tabs-nav-list {
  padding: 4px;
  background: #d9edec;
  border-radius: 6px;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-bottom {
  margin: 10px 0px 0px 0px !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: unset;
}

//////////////////////////////////////Listing Tabs////////////////////////////////////////////

// .ant-collapse-header {
//   margin-top: 8px !important;
// }

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.overViewReportTable .ant-table table {
  border-spacing: 1.5px;
}

.overViewReportTable .ant-table-tbody > tr > td {
  height: 5px;
}

.overViewReportTable .ant-table-thead > tr > th {
  height: 5px;
  background-color: white !important;
  text-align: center !important;
}

.bottomShiftBar {
  background: #e5fdf4;
  padding: 14px 0px;
  text-align: center;
  margin-top: 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.topShiftBar {
  background: #e5fdf4;
  padding: 14px 0px;
  text-align: center;
  margin-bottom: 12px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.grad-bar {
  height: 40px;
  background-color: red;
  /* For browsers that do not support gradients */
  background-image: linear-gradient(
    to right,
    red,
    rgb(255, 191, 0),
    #32cf4f,
    rgb(255, 191, 0),
    red
  );
  border-radius: 3px;
  border: 1px solid gray;
  height: 15px;
  width: 10rem;
  margin-left: 17%;
}

.pointer-1 {
  margin-top: -6px;
  margin-left: -86%;
}

.pointer-2 {
  margin-top: -6px;
  margin-left: -53%;
}

.pointer-3 {
  margin-top: -6px;
  margin-left: 0%;
}

.pointer-4 {
  margin-top: -6px;
  margin-left: 47%;
}

.pointer-5 {
  margin-top: -6px;
  margin-left: 85%;
}

.report-type {
  padding: 5px 15px;
  display: table;
  color: white;
  border-radius: 20px;
  font-size: 12px;
  background: $primary-bg-color;
}

.site-health {
  padding: 5px 10px;
  display: inline-block;
  color: white;
  border-radius: 4px;
  font-size: 10.5px;
}

.light-green {
  background: #228b22;
}

.unavailable {
  background: white;
  color: rgb(105, 105, 105);
}

.report-count {
  padding: 7px 12px;
  color: white;
  margin-right: 5px;
  border-radius: 50%;
}

.grey {
  background: #8f8d8d;
}

.darkGreen {
  background: #38764d;
}

.green {
  background: #5abe7c;
}

.amber {
  background: #ffbf00;
}

.red {
  background: #ea5455;
}

/* Styles For Historical Task Table */

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.components-table-demo-nested .ant-table-wrapper .ant-table-thead tr > th {
  text-align: center;
}

.complete-task-table .ant-table table {
  overflow: hidden !important;
}

.indicator.online {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: 0.4rem;
  animation: pulse-animation 2s infinite linear;
}

.rating-lable {
  display: inline-flex;
  align-items: center;
}

@keyframes pulse-animation {
  0% {
    -webkit-transform: scale(1);
  }

  25% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
  }

  75% {
    -webkit-transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.loading-animation-wrapper {
  position: fixed;
  /* Sit on top of the page content */
  display: block;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  z-index: 1000000;

  .loading-animation {
    position: absolute;
    left: 50%;
    top: 50%;

    height: 50px;
    width: 50px;
  }
}

.box {
  width: 180px;
  height: auto;
  background-color: #ffbf00;
  opacity: 0.5;
  padding: 11px;
  position: relative;
  display: inline;
}

.arrow-right:after {
  content: " ";
  position: absolute;
  right: 19px;
  top: -5px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid grey;
  border-bottom: 15px solid transparent;
}

.noCritical:after {
  border-left: 15px solid grey;
  color: grey;
}

.passedCritical:after {
  border-left: 15px solid red;
  color: red;
}

.predictionLabel:after {
  border-left: 15px solid grey;
  color: grey;
}

.daysToCritical:after {
  border-left: 15px solid #ffbf00;
  color: #ffbf00;
}

.concernBox {
  padding: 8px;
  background-color: yellow;
  margin-right: 10px;
}

.criticalBox {
  padding: 8px;
  background-color: red;
}

.strengthBox {
  padding: 10px 12px;
  border-radius: 4px;
  background-color: green;
  margin-left: 4px;
}

.boxText {
  color: white;
}

// .recharts-legend-wrapper {
//   margin-bottom: -20px !important;
// }

.custom-span {
  margin: 0 ($module-rem / 2) !important;
}

.customRangePicker {
  margin-right: -20px;

  .ant-picker-range {
    font-weight: bold;
    width: 89%;
    height: 33px;
    font-size: 12px;
  }

  .ant-picker-input > input {
    font-size: 12px;
  }
}

.ant-picker-range {
  border-radius: 6px;
  background-color: $default-bg-color;
  border: 1px solid #ebebeb;
  height: 36.5px;
  width: 100%;
}

.ant-picker.ant-picker-disabled {
  border: unset;
  color: rgba(0, 0, 0, 0.25);
}

.watchlistDropdown {
  width: 200px !important;
  font-size: 12px;
  color: black;

  label {
    margin-left: -53px;
    margin-right: 10px;
    color: #333333;
  }
}

.filterDropdown {
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 33px !important;
    font-size: 12px;
    background-color: $default-bg-color !important;
  }

  .ant-select.mb-lg-0.mb-4.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    margin-bottom: unset !important;
  }

  p.error {
    display: none;
  }
}

.ant-btn {
  padding: 1rem 0.7rem !important;
}

.layout .app-container .main-content .main-content-wrap {
  padding: 0 1.14286rem 0.95714rem !important;
}

.ant-input-affix-wrapper:not(textarea) {
  height: 44.5px !important;
}

.ant-input:not(textarea) {
  height: 42px !important;
}

.overflow-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.customHeader {
  padding-top: 1rem;
  position: sticky;
  top: 0;
  z-index: 7;
  background: $bg-color;
}

.formsFooter {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.ant-table-column-title {
  z-index: 0;
}

@media screen {
  .col-lg-3,
  .col-lg-6,
  .col-md-3,
  .col-3,
  .col-md-6 {
    @media #{$max767} {
      min-width: -webkit-fill-available;
    }

    @media only screen and (min-height: #{$minTabletHeight}) and (max-width: #{$maxTabletWidth}) {
      min-width: -webkit-fill-available;
    }
  }
}

.customTable {
  @media only screen and (min-height: #{$minTabletHeight}) and (max-width: #{$maxTabletWidth}) {
    margin-left: 10%;
    margin-right: 10%;
    max-width: -webkit-fill-available;
  }
}

.d-print {
  display: none;
}

.ant-modal-header {
  padding: 0 !important;
  background: transparent;
}

.ant-modal .ant-modal-header .title {
  padding: 2rem 2.2rem 0.1rem !important;
  margin: 0;
  width: 95%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: transparent;
}

.report-scale-modal-actions {
  float: right;
  margin-top: -10px;
  margin-bottom: 10px;
  margin-right: auto;
}

////role table
.role_permission {
  white-space: normal;
  font-weight: bold;
}

.role_actions_buttons {
  justify-content: center;
}

///////////////

.chartArea {
  background: $default-bg-color;
  // padding: 10px 10px 20px 10px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px 6px 0px 0px;
}

.reports__overall-status {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0 0 0;
}

.reports__overall-status-innerBox {
  height: 15rem;
  background: white;
  position: relative;

  &:not(:last-child) {
    margin-right: 15px;
  }

  // box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  > td {
    border-color: transparent;
    background: white !important;
  }

  border: 1px solid #cccccc;
  border-radius: 6px;

  .predictionChartContainer {
    max-height: 125px;
  }

  > .row {
    flex-wrap: nowrap !important;
    margin: 0px auto !important;
  }

  .predictionSmallCard p:last-child {
    letter-spacing: -0.5px;
    padding: 2px 3px;
  }

  .predictionLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.content-center-aligned {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-left-aligned {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}

.reports__overall-status-innerBox > p {
  font-weight: bold;
  font-size: 14px;
  padding: 8px 15px;
  margin-bottom: 0;
}

.ant-tooltip,
.ant-select {
  font-size: 13px;
}

.ant-modal-mask {
  background-color: rgba(#000, 0.7);
}

.ant-modal-content {
  border-radius: 24px;
}

.ant-modal .ant-modal-body {
  padding: 1.5rem;
  font-size: 13px;
}

.ant-modal-close {
  top: 9px !important;
  right: 9px;
  border-radius: 33px;
  background: #ea545540;
  color: #ea5455;

  &:hover {
    background: #ea545550;
    color: #ea5455;
  }
}

.ant-modal-close-x {
  width: 34px;
  height: 34px;
  font-size: 16px;
  line-height: 36px;
}

.popup-button-list {
  .ant-btn {
    margin-right: unset;
  }
}

.col-half-offset {
  margin-right: 3.166666667%;
}

.fixed-footer-wrapper {
  position: fixed;
  bottom: 0;
  right: 10px;
  left: $vertical-navbar-width-minified;
  background: $bg-color;

  .footer-container {
    display: flex;
    padding: 10px 0 10px 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.ant-pagination-options-quick-jumper {
  display: inline-flex;
  height: 40px;
  line-height: 17px;
  align-items: center;
}

.cursor-pointer {
  cursor: "pointer";
}

// .ant-breadcrumb {
//   font-weight: bolder;
//   font-size: 10px;
//   margin-right: 10px;
//   margin-bottom: 2px;
//   > span:last-child {
//     // color: #00000050;
//     color: #00000099;
//   }
//   a {
//     color: black;
//   }
// }

.bold-content {
  text-shadow: 0px 0px, 0px 0;
  margin-right: 5px;
}

.customHeaderDropdown {
  font-weight: bold;
  margin-top: 10px;
}

.dashboardHeader {
  .ant-btn-primary {
    background: white;
    border-color: #d9d9d9;
    color: black;
  }
}

.ratingAccuracy {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
}

.dottedActionsButton {
  display: inline;

  .ant-btn.ant-btn-primary:not(:disabled) {
    vertical-align: middle;
    border: 1px solid #aed7d3;
    box-sizing: border-box;
    background: transparent;
    color: #1b998f;
    height: 18px;
    border-radius: 3px;
    padding: 0.2rem 0 0 0 !important;
    font-size: 11px;
    margin: 0 0 0 18px;
    box-shadow: none;
    font-weight: unset;

    &:hover,
    &:focus {
      background: #1b998f;
      color: white;
    }
  }
}

.ant-btn.ant-btn-primary:not(:disabled) {
  background: $primary-bg-color;
  color: white;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $primary-bg-color;
    box-sizing: border-box;
    background-color: white;
    color: $primary-bg-color;
  }
}

.roleCountButton {
  border: 1px solid #e4e4e4;
  border-color: #e4e4e4 !important;
  box-shadow: none !important;

  &:hover {
    border: 1px solid $primary-bg-color;
    border-color: $primary-bg-color !important;
  }
}

.ant-btn.ant-btn-default {
  background: white;
  color: black;

  svg {
    fill: #6e6b7b;
  }

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $primary-bg-color;
    box-sizing: border-box;
    background-color: white;
    color: $primary-bg-color;

    svg {
      fill: $primary-bg-color;
    }
  }
}

.refresh-time-sub-categories {
  color: $secondary-bg-color;
  font-weight: bolder;
  margin-left: 7px;
}

.bandDropdownCol {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    // background-color: #fff;
    border-radius: 7px;
    height: 35px;
  }

  .ant-select-multiple .ant-select-selection-item {
    height: 18px;
    margin-top: 1px;
    margin-bottom: 1px;
    line-height: 16px;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  // border: 1px solid #ebebeb !important;
  height: 42px !important;
}

.inAppNotificationsListing,
.lookupsListing {
  .form-group {
    margin: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $default-bg-color !important;
    height: 36px !important;
  }

  .ant-form-item-label > label {
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    color: #535568;
  }
}

.ant-input:not(textarea) {
  background: white;
}

// .ant-table table {
//   border-spacing: 0 5px;
// }

.ant-table-wrapper {
  &:not(.trackedItemListing) .ant-table-tbody > tr > td {
    border-top: 6px solid white;
  }
}

.ant-checkbox-wrapper {
  line-height: unset;

  .ant-checkbox-inner:after {
    height: 11px;
    width: 5px;
  }

  &:not(.ant-checkbox-wrapper-checked):hover {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $primary-bg-color;

        &:after {
          border-color: $primary-bg-color;
        }
      }
    }
  }
}

.ant-checkbox-inner::after {
  top: 46%;
  left: 21%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-bg-color;
  border-color: $primary-bg-color !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  opacity: 50%;

  &::after {
    border-color: white;
  }
}

.pageDescription {
  display: flex;
  // align-items: center;
  background: #f4e1fd;
  border: 1px solid #d08bc9;
  border-radius: 6px;
  width: 100%;
  color: #6d1d82;
  margin-top: 8px;
  font-size: 12px;

  .anticon {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .descriptionArea {
    padding: 10px;
    max-height: 70px;
    width: 100%;
    overflow-y: auto;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

//////////////////////////////////////Forms////////////////////////////////////////////

.box-main-section {
  border: 1px solid #f4f4f4;
  margin-top: 12px;
  background: white;
  padding: 2rem 2rem 0.8rem;
  border-radius: 6px;

  .ant-btn {
    font-size: 12px;
    height: 34px;
  }

  .ant-btn.ant-btn-circle {
    font-size: 1rem;
    width: 34px;
  }

  .ant-tabs {
    .ant-tabs-nav {
      .ant-tabs-tab {
        width: 150px;
        font-weight: normal;
      }
    }
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-bottom {
    margin: 0 !important;
  }
}

.box-section {
  position: relative;
  width: 100%;
  font-size: 13px;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
  display: inline-block;
}

.box-title {
  position: absolute;
  top: -14px;
  padding: 8px 0;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.box-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.box-description {
  padding: 35px 0 0;
  font-size: 12px;
  line-height: 13px;
  color: #535568;
}

.ant-select-item-option-content {
  font-size: 12px;
}

.ant-picker-dropdown {
  font-size: 12px;
}

.dropdownTooltip {
  .ant-tooltip-inner {
    font-size: 11px;
  }
}

#my-form {
  margin-top: 15px;
}

.customForm,
.customFilterFields {
  .form-group .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label > label {
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    color: #535568;
    margin-right: 10px;
  }

  .ant-form-require-item-label > label::after {
    content: "*";
    color: red;
    margin-left: 5px;
  }

  .ant-select-arrow {
    font-size: 10px;
    font-weight: bold;
    color: #808191;
  }

  .ant-input-affix-wrapper .ant-input:not(textarea):first-child {
    height: 31px !important;
  }

  .ant-select-selector span,
  .ant-select-selection-search > input,
  .ant-select-selection-item div,
  .ant-picker-input,
  .ant-picker-input > input,
  span.ant-input-suffix,
  .ant-input-affix-wrapper .ant-input:not(textarea):first-child {
    font-size: 12px;

    &:hover,
    &:focus {
      border: none !important;
    }
  }

  .descriptionField {
    textarea.ant-input.null {
      height: auto !important;
      min-height: 32px !important;
    }
  }

  .ant-select-multiple .ant-select-selector,
  textarea.ant-input.null,
  span.ant-input-affix-wrapper.null,
  span.ant-input-affix-wrapper.input-error,
  .ant-input:not(textarea),
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px !important;
    background-color: white;
    border: #e4e4e4 1px solid;
    border-radius: 5px;
    color: #808191;
    font-size: 12px;
    line-height: 15px;

    &:hover,
    &:focus {
      background-color: white !important;
      border: 1px solid $primary-bg-color;
      border-color: $primary-bg-color !important;
      box-shadow: unset !important;
    }
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input[disabled],
  input.ant-input.ant-input-disabled.null,
  textarea.ant-input.null.ant-input-disabled,
  span.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled.null,
  .ant-picker.ant-picker-disabled {
    background: #f2f2f2;
    cursor: not-allowed;
  }

  span.ant-select-selection-item {
    max-width: 85%;
  }
}

//View Form
.viewForm {
  .box-description,
  .ant-tabs {
    font-size: 14px;
    color: black;
    font-weight: bolder;
    line-height: 1;
  }

  .box-title {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    padding: 5px 0;
  }

  label,
  .ant-checkbox-disabled + span {
    font-size: 12px;
    color: #00000080 !important;
    margin-bottom: 0.9rem;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: $primary-bg-color;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: white !important;
    border-color: white !important;
  }
}

//////////////////////////////////////Forms////////////////////////////////////////////

.layout .app-container .main-content.loaded .main-content-wrap {
  opacity: 1;
  transition: 0s opacity 0s ease-in-out;
  margin-left: 9px;
}

/* width */
// ::-webkit-scrollbar {
//   width: 15px;
// }

/* Track */
::-webkit-scrollbar-track {
  border: 1px ridge;

  &:hover {
    background: #f1f1f1;
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;

  /* Handle on hover */
  &:hover {
    background: #696969;
  }
}

.ant-form-item-label > label::after {
  content: unset;
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.specificClass svg path[id="text"] {
  display: none;
}
/* Add this CSS to your App.scss or any other CSS file */

// More or Less button
.more_or_less {
  font-weight: bold;
}