.exclamation-icon-watchlist {
  position: absolute;
  left: 14px;
  top: 13px;
  cursor: pointer;
}
.notification-column {
  padding-right: 0.76923rem !important;
  .notification-column-container {
    background-color: yellow;
    padding: 5px 15px;
    width: max-content;
    display: flex;
    margin: auto;
    border-radius: 3px;
    border: 1px solid yellow;
    cursor: pointer;
    & > .action-badge .ant-badge-count {
      padding: 0px;
      height: 12px;
      min-width: 12px;
      width: 12px;
      line-height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 9px;
      background-color: white;
      font-weight: bold;
      top: 5px;
      right: 5px;
      & > .ant-scroll-number-only {
        height: 100%;
      }
    }
  }
  .notification-column-container {
    svg,
    path {
      stroke: inherit;
    }
  }

  .notification-column-container:hover {
    svg,
    path {
      fill: inherit;
      stroke: inherit;
    }
  }
}
