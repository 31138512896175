html {
  height: 100%;
}
body {
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
  width: 100%;
}

#app {
  display: block;
  height: 100%;
}

* {
  outline: none !important;
}
