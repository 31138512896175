.notificationSettingsModal {
  //   width: 350px !important;
  width: 400px !important;

  .ant-radio-wrapper {
    display: block;
  }
  .ant-radio-button-wrapper:hover {
    color: #17a99a !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #17a99a !important;
    border-color: #17a99a !important;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    color: white !important;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: #17a99a !important;
  }
  .notificationSettingsModal .ant-modal-body {
    min-height: 400px;
  }
  .ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-inner {
    // border-color: #17a99a;
    background-color: white;
  }
  .ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-inner:after {
    background-color: #17a99a;
    transform: scale(1);
    opacity: 1;
  }
  .ant-radio-wrapper
    .ant-radio:hover:not(.ant-radio-disabled):not(.ant-radio-checked)
    .ant-radio-inner:after {
    background-color: #17a99a;
  }
  .ant-radio-wrapper:hover:not(.ant-radio-wrapper-checked):not(
      .ant-radio-button-wrapper-disabled
    )
    .ant-radio-inner:after {
    background-color: #17a99a;
    transform: scale(1);
    opacity: 1;
  }

  .ant-radio-wrapper .ant-radio .ant-radio-inner {
    border-color: #bcbaba;
  }

  .notificationSettingsFrequency {
    // margin-left: 20px;
    margin: 0 20px;
    & > div {
      position: relative;
      width: auto;
    }
    & > div:last-child {
      margin-bottom: 30px;
    }
  }

  .notificationSettingsFrequency + .form-group {
    margin: -5px 0px;
    button {
      width: 80px;
      margin: 0px;
    }
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    cursor: not-allowed !important;
  }
  .error {
    position: absolute;
    top: 92%;
  }

  .ant-tabs .ant-tabs-nav .ant-tabs-tab {
    width: auto;
    min-width: 90px;
    padding: 2px 4px;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 7px 0 !important;
  }
  .ant-tabs .ant-tabs-nav .ant-tabs-tab:not(:first-child) {
    margin-left: 5px !important;
  }
}
