$header-bg-color: #f0f0f0;
$border-color: #b6b6b6;
$border-width-color: 1px solid $border-color;
.multi-watch-list-table-wrapper {
  .ant-table tr th,
  .ant-table tr td {
    padding: 0.6rem 0.76923rem;
  }
  & > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    //  border: $border-width-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    & > .ant-table-container {
      //  & > .ant-table-content > table {

      //      border-collapse: collapse;
      & > .ant-table-header > table {
        border-collapse: collapse;
        & > colgroup {
          & > .ant-table-expand-icon-col {
            width: 40px !important;
          }
        }
        & > .ant-table-thead {
          & > tr > th {
            color: white;
            background-color: #2b3b4a !important;
            border-bottom: none !important;
          }
        }
      }
      & > .ant-table-body {
        overflow-y: auto !important;
        & > table {
          border-collapse: collapse;
          & > colgroup {
            & > .ant-table-expand-icon-col {
              width: 40px !important;
            }

            & > col:nth-child(2) {
              min-width: 120px;
              max-width: 300px;
            }
            & > col:nth-child(3),
            & > col:nth-child(4) {
              width: 180px;
            }
          } //first arrow column width
          & > .ant-table-tbody {
            background-color: white;
            & > tr {
              background-color: white;

              &:hover {
                box-shadow: none !important;
                //   background-color: white;

                & > td {
                  background-color: unset !important;
                  box-shadow: none !important;
                  border: $border-width-color !important;

                  &:nth-child(-n + 5) {
                    background-color: #eef8f7 !important;
                  }
                }
              }

              & > td {
                background-color: unset;
                border: $border-width-color;

                &:nth-child(-n + 5) {
                  background-color: #eef8f7;
                }
              }
            }

            & > tr.ant-table-expanded-row,
            & > tr.ant-table-expanded-row:hover {
              //expanded row container
              //  background-color: #f7fbfd;
              // border-left: 28px;
              & > td {
                //   border-bottom-width: 0px !important;
                .ant-table-expanded-row-custom-wrapper {
                  border-left: 39.5px solid #398f83;
                  background-color: #f7fbfd;

                  & > .expanded-row-wrapper-content-container {
                    border-left: $border-width-color;
                    padding: 1rem;
                    & > .pageDescription {
                      margin-top: 0px;
                      background-color: unset;
                      font-weight: bold;
                      & > span.anticon {
                        border-right: none !important;
                        background-color: unset !important;
                      }
                    }
                  }
                }
              }
            }

            ///left arrow column
            & > .ant-table-row.ant-table-row-level-0 {
              & > .ant-table-row-expand-icon-cell {
                background-color: $header-bg-color !important;
                padding-left: 0.6rem;
                border-right: 0.5px solid $border-color !important;
                position: relative;
                & > .anticon {
                  @extend .absolute-center;
                  background-color: white;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;

                  & > svg {
                    @extend .absolute-center;
                  }
                }
              }

              //parent expanded row
              &.expanded-row,
              &.expanded-row:hover {
                & > td {
                  border-bottom: 1px solid #e0e9ee !important;
                  background-color: white !important; //reset for first 4 column bg color
                  &:nth-child(2) {
                    //first child is arrow column
                    color: #398f83;
                    font-weight: bold;
                  }
                }
                & > .ant-table-row-expand-icon-cell {
                  //arrow column which is first child
                  border-bottom: 1px solid #398f83 !important;
                  background-color: #398f83 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.watchlist-table-wrapper {
  & > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-left: $border-width-color;
    border-right: $border-width-color;
    .ant-table-container table {
      border-collapse: collapse;

      .ant-table-thead {
        & > tr {
          height: 40px !important;
          & > th {
            background-color: #2b3b4a !important;
            color: white !important;
          }
        }
      }

      & > .ant-table-tbody {
        & > tr {
          &:hover {
            box-shadow: none !important;
            & > td {
              background-color: unset !important;
              box-shadow: none !important;
              border-bottom: $border-width-color !important;
              border-top: none !important;
            }
          }
          & > td {
            background-color: unset;
            border-top: none;
            border-bottom: $border-width-color;
          }
        }
      }
    }
  }
}

.watchlist-breadcrumb-container {
  
  margin: 1rem 0px;
  & > .breadcrum-bg {
    border-radius: 6px;
    //border-left: none;
    .breadcrumb-item {
      padding: 0px 20px 0px 30px;
      & > .breadcrumb-label {
        text-align: left;
        & > :last-child {
          font-weight: bold;
        }
      }
      &:last-child {
        background-color: #1b998f;
         .breadcrumb-label {
          color: white;
        }
         .arrow {
          background-color: #1b998f;
        }
      }
    }
  }
}
.absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
