.searchContainer {
  margin-right: 10px;
  width: 230;
  .ant-input-affix-wrapper {
    height: 41px !important;
    background-color: white;
    border-radius: 6px;
    .ant-input:not(:first-child) {
      height: 38px !important;
      padding-left: unset !important;
      margin-left: 3.07692rem !important;
    }
  }
}
