.no-notification {
  margin-bottom: 0;
  margin-top: 32px;
}
.view-more {
  font-size: 12px !important;
  margin-top: 14px !important;
  margin-left: 10px !important;
  color: #17a99a;
}
