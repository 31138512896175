@import "../../assets/sass/variables";

.layout.vertical {
  .app-container {
    padding-left: $vertical-navbar-width-minified - 0.5rem;

    .app-search {
      @media #{$max543} {
        display: none;
      }
    }

    .header-info-container {
      display: inline-flex;
      .header-title {
        min-width: max-content;
        display: flex;
        align-items: center;
      }

      .header-desc-border {
        margin: 2px 8px 2px 9px;
        width: 1px;
        background-color: #dbdbdb;
      }
      .header-desc-text {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        align-self: center;
      }
    }
    .navbar.horizontal {
      .logo {
        padding: 0;
      }
      .logo-img {
        height: 40px;
        max-height: 40px;
      }
      .navbar-skeleton {
        .sk-logo {
          display: none !important;
        }
      }

      .title-section-container {
        width: 72%;
      }
    }

    .footer {
      left: $vertical-navbar-width-minified;
    }

    .navbar.horizontal {
      box-shadow: 4px 3px 4px rgba(#000, 0.05);
      position: absolute;
      left: $vertical-navbar-width-minified;
      z-index: 11;
      right: 0;
      top: 0;
      // border: 1px solid #e8e8e8;
      min-height: $horizontalBarHeight !important;
    }
  }

  .assistant-menu {
    margin-top: auto;

    .menu-item .item-link {
      color: #000;
      opacity: 0.3;
      transition: opacity 0.2s ease-in-out;

      .link-icon {
        opacity: 1;
      }

      &:hover {
        color: #000;
        opacity: 1;
      }
    }
  }
}

.ant-result-subtitle {
  color: black;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
}

// .main-menu .main-menu-wrap .menu-ul {
//   list-style-type: none;
//   margin: 0;
//   padding: 2.14286rem 1.42857rem 0;
//   width: 109%;
// }

// .navbar.vertical {
//   width: 18.14286rem;
// }
