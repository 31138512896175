.opacity-zero {
  opacity: 0;
}
.progressBarContainer {
  margin-bottom: 1.5rem;
  .ant-space-item,
  .ant-space {
    width: 100%;
  }
  .ant-progress-bg {
    height: 20px !important;
  }
}
.loading-animation-wrapper.opacity-zero {
  z-index: 999999;
  visibility: hidden;
}
.ant-modal-wrap {
  // z-index: 99999998;
}
.progressBarModal {
  .ant-modal-body {
    text-align: right;
  }
}
.ant-select-dropdown {
  // z-index: 1000000000;
}
