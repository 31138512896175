.watchlistActions {
  .ant-btn.ant-btn-primary:not(:disabled) {
    height: 35px;
    padding: 0.2rem !important;
    margin: 0;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  }
}

.watchlistActionButtons {
  justify-content: center;
  svg {
    width: 25px;
    height: 25px;
  }
}

.watchlistTitleContainer h6 {
  margin-bottom: 0px;
  font-size: 15px;
}
.detailGraphBtn {
  margin-left: 20px;
  color: #1b998f;
}
.watchlistTitleContainer p {
  margin-bottom: 0px;
  color: #7d7d7d;
  font-size: 12px;
  line-height: 1.5;
}

.watchlistHierarchy {
  //     text-overflow: ellipsis;
  // overflow: hidden;
  white-space: normal;
}
// .watchlistTable .ant-table-tbody{
//     td,tr{
//        box-shadow: none !important;
//    }
// }

// .watchlistTable .ant-table-tbody tr:hover{
//        box-shadow: none !important;

//     td{
//        box-shadow: none !important;
//    }
// }
.watchlist-breadcrumb-container > .breadcrum-bg .breadcrumb-item{
  position: relative;
  padding: 0px 25px 0px 40px;
}
.breadcrum-bg{
  max-width: 98% !important;
}
.watchlist-breadcrumb-arrow-container{
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  z-index: 10;
   .arrow{
      transform: translateY(-10px) rotate(-45deg) skew(-13deg, -13deg) translateX(-25px);
  }
}
.watchlist-breadcrumb-text{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  text-align: left;
  flex-grow: 1;
  flex-shrink: 1;
  width: calc(100% - 35px);
  .watchlist-breadcrumb-item{
    text-align: left;
  }
}
.watchlist-breadcrumb-item {
  cursor: pointer !important;
  div{
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
}

.watchlistTitleContainer h6 {
  overflow: hidden;
  white-space: normal;
  // word-break: break-all;
}

.predictionRatings {
  background-color: #f3f3f3;
  margin: 0px !important;
  padding: 5px;
  font-size: 77%;
  line-height: 1.5;
  width: 90px;
  position: relative;
  z-index: 1;
}
.watchlistCard {
  margin: 0px 15px;
  max-width: 68px;
  width: 68px;
}
.reports__overall-status-innerBox:first-child {
  width: 21% !important;
  padding: 0px 5px;
}
.movingAverageAndProjection.reports__overall-status
  .reports__overall-status-innerBox:first-child {
  width: 25% !important;
}
.watchlistCard > .ant-card-body {
  padding: 0px !important;
  box-shadow: 0px 0px 0px 0.5px lightgray;
  min-width: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
  border-radius: 2px 2px 3px 3px;
}
.predictionCheckIcon {
  margin: 5px auto;
  width: 25px;
  height: 25px;
  border-radius: 30px;
}
.criticalMessages > div > p {
  margin: 0px;
  color: #1b998f;
  font-size: 14px;
}

.criticalMessages > p {
  margin: 5px 10px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
}
.criticalMessages > div > p {
  margin: 0px;
  color: #1b998f;
  font-size: 14px;
}

.criticalMessages > p {
  margin: 5px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  display: inline-block;
  padding: 5px;
  white-space: normal;
  font-size: 12px;
}
.zeroNotification .criticalMessages > div > p {
  color: rgba(0, 0, 0, 0.7);
}
.zeroNotification svg {
  path,
  rect {
    stroke: rgba(0, 0, 0, 0.7);
  }
}

.watchlistMessagesModal .ant-modal-content {
  min-height: 350px;
}
.criticalMessages {
  cursor: pointer;
}
.watchlistContainer {
  justify-content: center;
}
.reports__overall-status-innerBox .watchlistContainer {
  margin: 0px;
  justify-content: center;
  // width:calc(100% + 25px);
  // margin-left:-18px;
  h6 {
    width: 100%;
  }
  .currentRatingCard,
  .previousRatingCard {
    display: flex;
    flex-wrap: wrap;
    width: calc(50% - 10px);
    justify-content: center;
    white-space: normal;
  }
}

.customListing.watchlistTable .watchlistContainer {
  margin: 0px;
  justify-content: center;
  //  width:calc(100% + 25px);
  // margin-left:-18px;
  h6 {
    width: 100%;
  }
  .currentRatingCard,
  .previousRatingCard {
    display: flex;
    flex-wrap: wrap;
    width: calc(50% - 10px);
    justify-content: center;
    white-space: normal;
    .watchlistStatus {
      margin: 0px;
      padding: 4px 1px;
      width: 100%;
      white-space: normal;
      height: 100%;
    }
  }
}
.watchlistTrackItemListing .watchlistContainer {
  width: calc(100% + 25px);
  margin-left: -18px !important;
}
.expanded-row-wrapper-content-container
  .customListing.watchlistTable
  .watchlistContainer {
  .currentRatingCard,
  .previousRatingCard {
    width: calc(50% - 10px);
  }
}
.watchlistDays {
  color: grey;
  margin-top: 5px;
  font-weight: bold;
}
.watchlistStatus {
  color: black;
  margin: 0px;
  padding: 4px 1px;
  width: 100% !important;
  white-space: normal;
  height: 100%;
  line-height: 1.5;
  font-size: 20px;
  font-weight: bold;

  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: center;
}
.watchlistActionColum .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.wathclistPrintDropdown svg {
  fill: gray;
}

.watchlistPrintBtn {
  margin-right: 10px;
}
.viewLargeGraph {
  opacity: 0;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  // backdrop-filter: blur(4px);
  // -webkit-backdrop-filter:blur(4px);
  transition: 0.1s linear;
  position: absolute;
  width: calc(100% - 70px);
  height: 100%;
  left: 70px;
  top: 0;
  z-index: 4;
  color: white;
}
.historicalChart-tooltip p {
  margin: 0px !important;
}
.historicalChartScale {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
}
.historicalChartScale > span,
.historicalChartScale svg {
  height: 100%;
}

// .watchlistTable tr:hover
.viewLargeGraph:hover {
  opacity: 1;
}

.watchlistRating {
  color: white;
  margin: 0px;
  padding: 2.5px 1px;
  border-radius: 2px 2px 3px 3px;
  font-size: clamp(10px, 0.8vw, 12px) !important;
}
// .previousRatingCard,.currentRatingCard{
//     width:100px;
// }
.previousRatingCard > h6,
.currentRatingCard > h6 {
  margin-bottom: 3px;
  margin-top: 8px;
  font-size: 12px;
}
.predictionChartContainer {
  position: relative;
  max-height: 120px;
  width: auto;
  background-color: #2b3b4a;
  max-width: calc(100% - 20px);
  padding-bottom: 15px;
}
.predictionChartContainer svg {
  width: 100%;
  height: 100%;
}
.predictionChartBG,
.historicalChart:not(.historicalChartPopup),
.watchlistPredictionColum > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 100%;
  margin: 0px;
  height: 100%;
  height: 120px;
  flex-wrap: nowrap !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.watchlistPredictionColum .predictionLoader {
  height: auto !important;
}
.historicalChart > .row {
  margin: 0px;
}

.historicalChart > .row > div {
  margin-top: -15px;
  margin-bottom: 15px;
}
.predictionRatings,
.predictionChartContainer {
  margin-top: -10px !important;
  margin-bottom: 10px !important;
  flex-shrink: 5;
}
.nextDays {
  font-weight: bold;
  margin-top: 10px;
  font-size: 12px;
  // margin-top:-4px; Top Scenario
}
.historicalChartPopup {
  margin: 80px 20px;
  margin-top: 0px;
}
.historicalChart svg {
  overflow: visible;
}
.predictionSmallCardContainer {
  position: absolute;
  width: 100%;
  bottom: 0px;
  margin: 0px !important;
  padding: 0px 5px;
}
.predictionSmallCard {
  min-width: 30px;
  width: auto;
  background-color: #2b3b4a;
  color: white;
  font-weight: bold;
  margin-top: 0px;
  align-self: flex-start;
  position: relative;
  z-index: 6;
  flex-grow: 0;
  p:first-child {
    margin: 0px;
    line-height: 1.5;
    font-size: 14px;
    display: none;
  }
  p:last-child {
    margin: 0px;
    line-height: 1.5;
    font-size: 11px;
    padding: 2px 5px;
    z-index: 3;
    min-width: 100%;
    background-color: transparent !important;
  }
}
.watchlistTable {
  overflow: hidden;
}
.watchlistLoading {
  position: absolute;
  left: -2%;
  padding: 0px 40px;
  width: 103%;
  background: white;
  z-index: 2;
  height: 45px;
  bottom: 10px;
  overflow: hidden;
  svg {
    margin-bottom: -8px;
  }
}

.watchlistRatingColum .watchlistContainer {
  min-height: 190px;
}

.watchlistTable tr:hover .watchlistChartColum {
  overflow: visible;
}
.watchlistTable tr:hover {
  position: relative;
  z-index: 1;
}
.historicalChartPopup {
  width: 100%;
  height: 30rem;
  max-height: 80vh;
}
.currentRatingContainer p:last-child {
  left: 0px;
}

// .historicalChart > .row > div {
//   margin-top: -15px;
//   margin-bottom: 15px;
// }
// .predictionRatings,
// .predictionChartContainer {
//   margin-top: -10px !important;
//   margin-bottom: 10px !important;
// }
// .nextDays {
//   font-weight: bold;
//   margin-top: -4px;
// }
// .historicalChartPopup {
//   margin: 40px 20px;
//   margin-top: 0px;
// }
// .historicalChart svg {
//   overflow: visible;
// }
// .predictionSmallCard {
//   min-width: 30px;
//   width: auto;
//   background-color: #2b3b4a;
//   color: white;
//   font-weight: bold;
//   margin-top: -10px;
//   align-self: flex-start;
//   position: relative;
//   z-index: 6;
//   flex-shrink: 0;
//   p:first-child {
//     margin: 0px;
//     line-height: 1.5;
//     font-size: 14px;
//   }
//   p:last-child {
//     margin: 0px;
//     line-height: 1.5;
//     font-size: 10px;
//     // position:absolute;
//     z-index: 3;
//     min-width: 100%;
//   }
// }
.predictedRatingContainer p:last-child {
  right: 0px;
}

.watchlistTable tr * {
  text-overflow: initial !important;
}

.recharts-xAxis .recharts-label,
.recharts-yAxis .recharts-label {
  tspan {
    font-size: 12px;
    font-weight: bold;
  }
}
.historicalChart:not(.historicalChartPopup) .recharts-xAxis .recharts-label,
.historicalChart:not(.historicalChartPopup) .recharts-yAxis .recharts-label {
  tspan {
    font-size: 10px;
  }
}
.historicalChartPopup svg.recharts-surface {
  margin: 0px;
}
.historicalChartModal {
  width: 1600px !important;
  height: auto;
  max-width: 80%;
  max-height: 80vh;
}
.historicalChartModal {
  h5,
  h6 {
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 15px;
    line-height: 1;
  }
}
.watchlistTable {
  overflow: hidden;
}
.watchlistLoading {
  position: absolute;
  left: -2%;
  padding: 0px 40px;
  width: 103%;
  background: white;
  z-index: 2;
  height: 45px;
  bottom: 10px;
  overflow: hidden;
  svg {
    margin-bottom: -8px;
  }
}

.watchlistTable tr:hover .watchlistChartColum {
  overflow: visible;
}
.watchlistTable tr:hover {
  position: relative;
  z-index: 1;
}
.historicalChartPopup {
  width: 100%;
  height: 30rem;
  max-height: 80vh;
}

.historicalChartModal .ant-modal-content {
  height: 100%;
}
.historicalChartModal .ant-modal-body {
  height: 100%;
  padding: 1.5rem 50px;
}

.historicalChartModal .ant-modal-body > div {
  height: auto;
}
.historicalChartPopup > .row {
  min-height: inherit;
}
.historicalChartPopup svg.recharts-surface {
  height: inherit !important;
}

// UI CSS changes
// .ant-table-thead{
//     background-color:#2B3B4A;
//     th{color:white !important;}
// }
// .ant-table-filter-trigger{
//     color:white
// }
// .ant-table-filter-trigger:hover{
//     color:white;
// }
// .ant-table-column-sorter:hover{
//     color:white
// }
// .ant-table-column-sorter{
//     color:white
// }
// .navbar.vertical .navbar-wrap{
//     background-color: #2B3B4A;
// }
// .main-menu .main-menu-wrap .menu-ul .menu-item .item-link svg{
//     stroke: white;
// }
// .main-menu .main-menu-wrap .menu-ul .menu-item .item-link svg path{
//     stroke: white;
// }
// .main-menu .main-menu-wrap .menu-ul .menu-item .item-link{
//     color:white;
// }
// th.ant-table-cell.ant-table-cell-scrollbar{
//     display:none !important;
// }
.historicalChartLabels {
  margin: 5px 0px 0px 63px !important;
  p {
    border-radius: 10px;
    padding: 1px 5px;
    font-weight: bold;
    font-size: 9px;
    margin: 0px 2px;
    background-color: #d9edec;
    span {
      font-weight: bold;
      text-shadow: 0px 0px, 0px 0;
    }
  }
}
.historicalChartPopup .historicalChartLabels {
  margin: 35px 0px 0px 0px !important;
  p {
    padding: 4px 10px;
    margin: 0px 5px;
    font-size: 12px;
  }
}
// .reports__overall-status-innerBox .historicalChartLabels{
//   margin-right:40px !important;
// }
.watchlistCard p {
  margin-bottom: 0px;
}
@media (min-width: 1880px) {
  .predictionChartBG,
  .historicalChart:not(.historicalChartPopup),
  .watchlistPredictionColum > div {
    height: 145px;
  }
  .predictionChartContainer {
    max-width: auto;
    max-height: 145px;
  }
  .predictionRatings {
    font-size: 90%;
    width: 90px;
  }
  .historicalChartLabels {
    margin: 2px 30px 0px 0px !important;
    p {
      font-size: 11px;
    }
  }
  .historicalChartPopup .historicalChartLabels {
    margin: 30px 0px 0px 0px !important;
    p {
      padding: 2px 10px;
      font-size: 11px;
    }
  }
  .reports__overall-status-innerBox .historicalChartLabels {
    margin-right: 80px !important;
  }

  .reports__overall-status-innerBox .watchlistContainer {
    .currentRatingCard,
    .previousRatingCard {
      max-width: 145px;
    }
  }
  .customListing.watchlistTable .watchlistContainer {
    .currentRatingCard,
    .previousRatingCard {
      max-width: 145px;
    }
  }
  .expanded-row-wrapper-content-container
    .customListing.watchlistTable
    .watchlistContainer {
    .currentRatingCard,
    .previousRatingCard {
      max-width: 145px;
    }
  }
  .movingAverageAndProjection.reports__overall-status
    .reports__overall-status-innerBox:first-child {
    width: 20% !important;
  }
}

.loadDataClass {
  .ant-table-cell-ellipsis {
    white-space: initial;
  }
}
