////////// Print Media ///////////////////

// @page {
//   margin-bottom: 2.6cm;
//   // margin-bottom: 2.75cm;
// }

@media print {
  html,
  body {
    width: fit-content;
    height: fit-content;
    // width: 375mm;
    // height: 250mm;
    // background-color: green;
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .ant-tree {
    padding-bottom: 22px;
    background: rgba(241, 249, 252, 0.09);
  }
  .ant-tree-treenode {
    display: -webkit-inline-box !important;
    page-break-inside: avoid;
    background: rgba(241, 249, 252, 0.09) !important;
  }
  .ant-tree-switcher {
    margin: 7px 10px 7px 30px !important;
    // page-break-inside: avoid !important;  // not being applied bcz of display flex
  }
  .parentNode.ant-tree-switcher {
    margin: 8px 11px 4px 11px !important;
  }
  .Roadmap .ant-tree-indent-unit::before {
    margin-top: -20px !important;
  }
  .Roadmap .ant-tree .ant-tree-treenode::after {
    margin-top: 0 !important;
    border: 32px solid #398f83 !important;
  }
  .parentNode.ant-tree-treenode.ant-tree-treenode-switcher-close::after {
    border: 34px solid #f0f0f0 !important;
  }
  .Roadmap .parentNode span.ant-tree-node-content-wrapper {
    padding-left: 10px;
    transform: translateY(-75%);
    position: sticky;
    top: 30%;
  }

  .chartValues {
    position: absolute;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .page-title {
    color: rgba(31, 32, 34, 0.5);
    font-size: 1.5rem;
  }
  .box-section {
    margin-top: 10px;
  }
  .ant-table-body {
    overflow-y: hidden !important;
    max-height: max-content !important;
  }
  .page-break {
    margin-top: 1rem;
    page-break-before: auto;
  }
  .d-print-none {
    display: none !important;
  }

  .d-print {
    display: block !important;
  }

  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      width: #{percentage(round($i * 8.33) / 100)} !important;
    }
  }

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      width: #{percentage(round($i * 8.33) / 100)} !important;
    }
  }

  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      width: #{percentage(round($i * 8.33) / 100)} !important;
    }
  }
  .reportPrintMode * {
    font-size: 98.5% !important;
  }
  .reportColumnValue {
    padding: 5px 2px !important;
  }
  .reportColumnValue > span {
    white-space: initial !important;
  }
  .reportPrintMode.fixedColumnListing
    .ant-table-tbody
    > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td:not(:first-child) {
    text-align: center;
    padding-left:2px;
    padding-right:2px;
  }
  .reportPrintMode td,
  .reportPrintMode th {
    background-color: #fff !important;
  }
  .reportPrintMode .reportColumnValue {
    padding: 10px 2px;
  }

  .reportPrintMode td,
  .reportPrintMode th {
    background-color: #fff !important;
  }

  .reportPrintMode
    .ant-table-row.nestedRow:not(.expandedRow)
    .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
    .ant-table-cell-content
    > span:first-child,
  .ant-table-row.nestedRow:not(.expandedRow)
    .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
    .ant-table-cell-content
    > span:first-child {
    white-space: initial !important;
    overflow: visible !important;
  }

  .ant-tabs-tab {
    background-color: white;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-bottom {
    margin: 0px !important;
    padding-top: 10px !important;
    background-color: #fff !important;
  }
  .customListing,
  .dashboardListing,
  .trackedItemListing {
    margin-top: 0px !important;
    padding-top: 20px !important;
    padding-bottom: 10px !important;
    background-color: #fff !important;
  }
  body {
    background-color: #fff !important;
  }
  .page-header.customHeader {
    margin-bottom: 10px;
  }
  .ant-table-body {
    padding-bottom: 30px;
  }
  .fixedColumnListing .ant-table-wrapper .ant-table-thead tr > th {
    text-align: center !important;
  }

  .customListing .ant-table-wrapper .ant-table-tbody > tr > td,
  .dashboardListing .ant-table-wrapper .ant-table-tbody > tr > td,
  .trackedItemListing .ant-table-wrapper .ant-table-tbody > tr > td,
  .customHeader,
  th.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last,
  #app *,
  .ant-table-thead > tr > th:first-child,
  .fixedColumnListing
    th.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last,
  .fixedColumnListing
    th.ant-table-cell.ant-table-row-expand-icon-cell.ant-table-cell-fix-left,
  .ant-tabs-nav-wrap,
  .ant-tabs-nav-list,
  .ant-table-wrapper .ant-table-thead tr > th {
    background-color: #ffffff !important;
  }
  div[data-test="financial-reports-table"]
    .ant-table-wrapper
    .ant-table-header
    .ant-table-thead
    .ant-table-cell {
    background-color: white !important;
  }
  .reportsListingTabs > .ant-tabs-nav {
    margin: 15px 0px !important;
  }
  .reports__overall-status-innerBox .customBarChart {
    margin-right: 150px;
  }
  .dashboardListing .customBarChart {
    margin-right: 120px;
  }
  .projection-chart svg.recharts-surface {
    margin-top: 30px;
  }
  .row.chartValues {
    margin-top: -40px;
  }
  .relations_container .header_text {
    margin: 0px 0px 20px 0px !important;
    display: block;
  }

  .historicalRoleTab {
    margin-top: 20px;
    page-break-before: always;
  }
  .recharts-text.recharts-label {
    margin-top: -10px !important;
  }
  .chartValues {
    padding-top: 30px;
    max-width: 1400px !important;
    width: 1400px;
  }
  .reports__overall-status-innerBox .predictionChartContainer .nextDays{
    margin-top:15px  !important;
  }
  .relations_container {
    margin: 30px auto 0px !important;
    width: 1400px;
    max-width: 1400px !important;
  }
  .notification-dropdown-container .ant-table-content tr span {
    white-space: initial;
  }
  .visionCommentsContainer > span:first-child {
    width: 100%;
    padding-right: 0px;
  }
  .ant-table-thead
    .ant-dropdown-trigger.ant-table-filter-trigger
    .anticon.anticon-filter {
    display: none;
  }
  .watchlistTable .predictionRatings {
    font-size: 66% !important;
  }
 .watchlistTable .ant-table-thead tr{
    font-size: 90% !important;
  }
 .predictionChartContainer .nextDays{
   margin-top:4px;
 }
 .watchlistTable .historicalChart .recharts-responsive-container svg.recharts-surface,.historicalChartPopup  .recharts-responsive-container svg.recharts-surface{
    width:100% !important

  }
  .reports__overall-status-innerBox .historicalChart .recharts-responsive-container svg.recharts-surface{
width:99%  !important;
  }

  .tableTree .ant-table-wrapper .ant-table-thead tr > {
    th.TableTreeNameCol{background-color: #2B3B4A !important;}
  .PreviousRatingCol,.CurrentRatingCol,.PredictedRatingCol,.notification-column{
    background-color: #0F1F2E !important;
  }
  }
  .fixed-footer-wrapper.d-print-none.dashboard-footer{
    display:block !important;
    position:relative;
    right: auto;
    left: auto;
    bottom: auto;
  }
  

.multi-watch-list-table-wrapper .ant-table-container {
  .ant-table-body{
    overflow: hidden !important;
  }
    colgroup {
            & > col:nth-child(1) {
              width: 40px !important;
            }

            & > col:nth-child(2) {
              width:200px !important;
            }
            & > col:nth-child(3),
            & > col:nth-child(4) {
              width: 180px !important;
            }
            & > col:nth-child(5),
            & > col:nth-child(6),
            & > col:nth-child(7) {
              width: 220px !important;
            }
            & > col:nth-child(8) {
              width: 100px !important;
            }
          }
}
.multi-watch-list-table-wrapper .ant-table-container .ant-table-header table{
  colgroup {
           

            & > col:nth-child(2) {
              width:210px !important;
            }
            
          }
}

  
}
.printMode .ant-tabs-content-holder > .ant-tabs-content {
  display: block;
}
.printMode .ant-tabs-nav {
  display: none;
}
.printMode .ant-tabs-tabpane > .box-heading.small {
  font-size: 13px;
}
.printMode {
  .site-collapse-custom-collapse
    .site-collapse-custom-panel
    .ant-collapse-content-active,
  .collpase-menu-parent {
    max-height: max-content !important;
    height: auto !important;
  }
}
///not for mac safari
@media print and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    html,
    body {
      width: 375mm;
      height: 250mm;
      // background-color: red;

      // width: fit-content;
      // height: fit-content;
      color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
  }
}

////////Print Media //////////////////
