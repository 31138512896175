.selected_filter {
  font-size: 11px;
  .filter_text {
    font-weight: bold;
  }
  &_name {
    // min-width: min-content;
    width: max-content;
    padding: 0 12px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    color: black;
    background: #d9edec;
    border: 1px solid #f2f2f2;
  }
}
