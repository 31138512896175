@import "../../../../assets//sass/variables";

.inAppNotificationsListing {
  .ant-table-wrapper .ant-table tr th,
  .ant-table-wrapper .ant-table tr td {
    padding: 0.4rem;
  }
}

.custom-notification-menu {
  .ant-btn {
    height: 30px;
    background: transparent !important;
    box-shadow: unset;
    color: $primary-bg-color !important;
    &:hover {
      border: none !important;
    }
  }
  .ant-dropdown-menu-item {
    font-size: 13px;
    border-bottom: 1px solid #0302290d !important;
  }
  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.action-item {
    padding: 0rem $module-rem;
  }
  span.message {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span.ant-dropdown-menu-title-content {
    padding: $module-rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}

// .custom-notification-menu {
//   .ant-dropdown-menu-item {
//     border-bottom: 1px solid #0302290d !important;
//   }
// }

.checkBox-rangePicker {
  padding-top: 24px;
  @media #{$max767} {
    padding-top: 0px;
    padding-bottom: 15px;
  }
  @media only screen and (min-height: #{$minTabletHeight}) and (max-width: #{$maxTabletWidth}) {
    padding-top: 0px;
    padding-bottom: 15px;
  }
}

.model-flag {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.flag-icon {
  font-size: 1.7em;
  margin-left: 8px;
}

.unread-icon {
  margin-right: 10px;
}

.notification-actions {
  border: none !important;
  color: rgb(105, 105, 105) !important;
  &:hover,
  &:focus {
    background: transparent !important;
    color: rgb(49, 49, 49) !important;
  }
}

.listingIcon {
  background: transparent;
  box-shadow: none;
  &:hover,
  &:focus {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}

.unreadNotification {
  font-weight: bold;
  td.ant-table-cell.ant-table-cell-ellipsis {
    background: #17a99a1a !important;
    &:hover {
      background: #17a99a1a !important;
    }
  }
}

// @media only screen and (min-width: 620px) {
//   .inAppPopup {
//     min-width: 655px !important;
//   }
// }

@media only screen and (min-width: 700px) {
  .inAppPopup {
    width: 830px !important;
  }
}

// .u-row .u-col-100 {
// min-width: 716px !important;
// }

.u-row {
  margin: unset !important;
  width: 100% !important;
  min-width: 100% !important;
}

.notificationTemplate {
  // table{
  //   max-width: 100%;
  // }
  border: 1px ridge;
//   .breadcrumbTable{
//     width: 100%;
//   }
//   .breadcrumbTable tr td:nth-child(odd){
// width: 20%;
//   }
//   .breadcrumbTable tr td:nth-child(even){
//     width: 15px;
//   }
//   table {
//     margin-bottom: 0;
//     max-width: 100%;
//     table-layout: fixed;
//     .breadcrumbItem{
//       max-width: 100%;
//       overflow: hidden;
//       display: inline-block;
//       line-height: 1;
//       span,strong{
//       max-width: 100%;
//       display: inline-block;
//       width: 100%;
//       overflow: hidden;
//       white-space: nowrap;
//       text-overflow: ellipsis;
//       min-width: 0px;
//     }
//   }
//   }
  th.tableheader:not(th.lefttableheader) {
    text-align: center !important;
  }
  th.lefttableheader {
    min-width: 130px;
  }
  // .iconsBg td:first-child {
  //   padding: 10px 30px 12px 0 !important;
  // }
  // .iconsBg td:nth-child(2) {
  //   padding: 10px 0 12px 0 !important;
  // }
  .textArea {
    font-size: 14px;
    padding: 10px 0;
  }
  .breadcrumbItem,
  .buttonL {
    font-size: 10px;
    padding: 6px;
  }
  .descriptionText {
    max-height: 65px !important;
  }

  .emailFooterTable {
    margin-top: -15px !important;
  }

  // img.logoImg {
  //   content: url("favicon.png");
  // }
  img.logoImg {
    content: url("../images/whiteLogo.png");
  }
  img.emailImg {
    content: url("../images/emailIcon.png") !important;
  }
  img.infoImg {
    content: url("../images/descriptionInfo.png") !important;
  }
  img.visionImg {
    content: url("../images/goToRodaBI.png") !important;
  }
  img.feedbackImg {
    content: url("../images/feedbackNew.png") !important;
  }
  img.arrowImg {
    content: url("../images/arrowIcon.png") !important;
  }
  img.beforeArrowImg {
    content: url("../images/beforeArrow.png") !important;
  }
  img.activeArrowImg {
    content: url("../images/activeArrow.png") !important;
  }
  // .emailFooter {
  //   padding: 5px 25px 25px !important;
  // }

  body,
  table,
  span,
  td,
  div,
  p,
  h1,
  h2,
  h3,
  h4,
  ol,
  li,
  ul {
    font-family: $main-ff, sans-serif !important;
  }
}
// body{
//   font-family:  $main-ff, sans-serif !important;
// }

// .inAppNotificationsListing *{
//   font-family:  $main-ff, sans-serif !important;

// }
