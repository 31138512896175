.ant-collapse {
  background-color: unset;
  border: unset;
}

.ant-checkbox + span {
  padding-right: 0;
  padding-left: 8px;
  // margin-bottom: 1.5rem;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 100%;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
  background-color: white;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  width: 15px;
  height: 15px;
  padding: 2px;
  background: unset;
  border: unset;
  border-radius: 2px;
}

.ant-collapse-header {
  font-weight: bolder;
}

.ant-checkbox-wrapper {
  font-size: 12px;
  color: #3b3b3b;
}

.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px 16px;
  h6 {
    margin-bottom: 0.4rem;
  }
}
