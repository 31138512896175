.relationsListing {
  .ant-table-wrapper {
    .ant-table-tbody > tr > td {
      background: white;
      border-bottom: 1px solid #e9e9e9 !important;
      border-top: unset !important;
    }
  }
  .ant-table-tbody
    > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
    &:hover {
      > td {
        box-shadow: unset !important;
        border-color: #e9e9e9 !important;
        background: white !important;
      }
    }
  }
}

tr.ant-table-row.ant-table-row-level-0.edit-relation-row {
  td.ant-table-cell {
    background: #f2f2f2 !important;
  }
  .ant-switch {
    border: 1px solid #686868;
    background-color: #686868 !important;
    &.ant-switch-checked {
      background-color: #02b9a3 !important;
      border: none;
      mix-blend-mode: normal;
      .ant-switch-handle::before {
        background-color: white;
      }
    }
    .ant-switch-handle::before {
      background-color: white;
    }
  }
}

.edit-relation-row {
  td.ant-table-cell {
    background: #f2f2f2 !important;
    // &:hover {
    //   background: #f2f2f2 !important;
    // }
  }
  //   td.ant-table-cell.ant-table-cell-ellipsis {
  //     background: #f2f2f2 !important;
  //   }
  .ant-switch {
    border: 1px solid #686868;
    background-color: #686868 !important;
    &.ant-switch-checked {
      // &.disableSwitch {
      //   // background-color: grey !important;
      //   opacity: 40%;
      //   pointer-events: none;
      // }

      background-color: #02b9a3 !important;
      border: none;
      mix-blend-mode: normal;
      .ant-switch-handle::before {
        background-color: white;
      }
    }
    .ant-switch-handle::before {
      background-color: white;
    }
  }
}

.sortButton {
  border: none;
  background: transparent !important;
  cursor: context-menu;
  &:hover {
    border: none !important;
  }
  // .ant-btn.ant-btn-default {
  //   &:focus {
  //     border: none !important;
  //     border-color: transparent !important;
  //   }
  // }
}
.notification-dropdown-container {
  display: flex;
  position: relative;
  .ant-tabs-nav {
    margin-top: 26px !important;
    margin-bottom: 26px !important;
  }
}

.notification-dropdown {
  position: absolute;
  right: 0px;
  top: 21px;
  width: 379px;
  margin: 0px;
  cursor: pointer;
  .ant-select-selector {
    background-color: white !important;
  }
}

.rowError {
  color: #ea5455;
  font-size: 10px;
  line-height: initial;
}

.buttons-list-center {
  justify-content: center;
}

.universal-scale-label {
  width: 100%;
  text-align: center !important;
}

.currencyFormatCol {
  height: 100%;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  label {
    margin-bottom: 0;
  }
}
.defaults-tabs-container.ant-tabs .ant-tabs-nav .ant-tabs-tab,.definition-tabs-container.ant-tabs .ant-tabs-nav .ant-tabs-tab{
  min-width: fit-content;
  width: auto;
}
[data-test="tracked-item-information-component"] input{
  padding-right: 0px !important;
  }
